import { useParams } from 'react-router-dom';
import { TabContext, TabPanel } from '@mui/lab';
import Tabs from '../../../components/v2/Tabs';
import { Tab } from '@mui/material';
import React from 'react';
import { useUser } from 'common/hooks/user';

export const NavigationTabs = ({ defaultTab, onTabChange, tabs }) => {
  const params = useParams();
  const { user } = useUser();

  const selectedTab = params.tab ?? defaultTab;
  return (
    <TabContext value={selectedTab}>
      <Tabs value={selectedTab} onChange={onTabChange} indicatorColor="secondary" variant="scrollable" scrollButtons="auto">
        {tabs.map(tab => {
          const display = <Tab key={tab.value} value={tab.value} label={tab.label} />;
          return tab.acl ? tab.acl(user) && display : display;
        })}
      </Tabs>
      {tabs.map(tab => {
        const display = (
          <TabPanel key={tab.value} value={tab.value}>
            {<tab.Component aria-label={`tab ${tab.value}`} />}
          </TabPanel>
        );
        return tab.acl ? tab.acl(user) && display : display;
      })}
    </TabContext>
  );
};
