import { useInfiniteQuery, useQuery, useQueries } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';
import { addDays, parse } from 'date-fns';
import { isUndefined, keyBy, keys, size } from 'lodash';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import { FEATURE_FLAG, OWN_ACCOMMODATION_SUPPLIER_CODE } from 'itrvl-types';
import { useUser } from 'common/context/UserContext';
import { PAGE_LIMIT, STORE_LOCAL_STORAGE_KEY } from './constants';
import { useCustomMutation, useQuoteOnly } from './hooks';
import { useBuilderStore } from './store';
import { formatDate } from './utils';

import logger from 'itrvl-logger';
const log = logger(__filename);
log.trace(__filename);

export const roomsForSupplierQueryKey = (supplierCode, startDate, endDate) => [
  'availability-calendar-rooms',
  supplierCode,
  startDate,
  endDate,
];

export const useRoomsForSupplierQuery = (supplierCode, { startDate, endDate } = {}) => {
  const Api = useApi();
  const query = useQuery({
    queryKey: roomsForSupplierQueryKey(supplierCode, startDate, endDate),
    queryFn: async () => {
      const response =
        startDate && endDate
          ? await Api.getRoomsBySupplierWithDates(supplierCode, startDate, endDate)
          : await Api.getRoomsBySupplier([supplierCode]);
      return response?.data;
    },
    staleTime: 1000 * 60 * 10,
    cacheTime: 1000 * 60 * 30,
    keepPreviousData: true,
  });
  return query;
};

export const useCalendarBySupplierWithRooms = (segment, dateString, isQuoteOnly, ..._rest) => {
  const supplierCode = segment?.supplierCode;
  const startDate = dateString;
  const endDate = dateString;
  const rooms = segment?.rooms?.map(room => `${room.room.roomTypeId}-${room.room.optionKey}`) ?? [];
  let wait;

  const isOwnAccommodation = supplierCode === OWN_ACCOMMODATION_SUPPLIER_CODE;

  const Api = useApi();
  const query = useQuery({
    queryKey: [
      'specific-availability',
      {
        supplierCode,
        startDate, // One day at a time.
        rooms,
      },
    ],
    queryFn: () => Api.calendarBySupplier(supplierCode, startDate, endDate, undefined, [], rooms, wait),
    enabled: Boolean(segment && !isOwnAccommodation),
    staleTime: 1000 * 60 * 10,
    cacheTime: 1000 * 60 * 30,
    keepPreviousData: true,
  });

  if (!segment || isQuoteOnly || isOwnAccommodation) return {}; // Do not search on non-selected days.

  return query;
};

export const useCalendarBySupplierWithRoomsMulti = (segments, isQuoteOnly, ..._rest) => {
  const Api = useApi();

  const queries = [];
  for (let segment of segments) {
    const supplierCode = segment?.supplierCode;
    const startDate = segment.startDateString;
    const endDate = segment.endDateString;
    const rooms = segment?.rooms?.map(room => `${room.room.roomTypeId}-${room.room.optionKey}`) ?? [];
    let wait;

    const isOwnAccommodation = supplierCode === OWN_ACCOMMODATION_SUPPLIER_CODE;

    if (!isOwnAccommodation) {
      queries.push({
        queryKey: [
          'specific-availability',
          {
            supplierCode,
            startDate,
            endDate,
            rooms,
          },
        ],
        queryFn: () => Api.calendarBySupplier(supplierCode, startDate, endDate, 0, [], rooms, wait),
        staleTime: 1000 * 60 * 10,
        cacheTime: 1000 * 60 * 30,
        keepPreviousData: true,
      });
    }
  }

  const query = useQueries({
    queries,
  });

  if (isQuoteOnly) return {};

  return query;
};

export const useInclusionsBySupplierQuery = (supplierCode, startDate, endDate) => {
  const Api = useApi();
  const query = useQuery({
    queryKey: [
      'itinerary-builder-inclusions',
      {
        supplierCode,
        startDate,
        endDate,
      },
    ],
    queryFn: () => Api.getInclusionsBySupplier([supplierCode], startDate, endDate),
  });
  return query;
};

const copyToBuilderQuery = camps => {
  const query = {
    fields: [
      'supplierCode',
      'campName',
      'regionName',
      'campInfo',
      'country',
      'dmcArrangedOnly',
      'consultantInteractionRequired',
      'restOfWorld',
      'campFeatures',
      'featuredImageId',
      'online',
      'minChildAge',
    ],
    order: 'campName ASC',
    where: { _id: { inq: camps } },
    include: ['featuredImage'],
  };

  return query;
};

const campsQuery = (term, searchMode, onlineMode, restOfWorldMode, regions, limit = 20, skip = 0) => {
  const query = {
    fields: [
      'supplierCode',
      'campName',
      'regionName',
      'campInfo',
      'country',
      'dmcArrangedOnly',
      'consultantInteractionRequired',
      'restOfWorld',
      'campFeatures',
      'featuredImageId',
      'online',
      'minChildAge',
    ],
    order: 'campName ASC',
    where: {
      // terms search supersedes other query params
      ...(term && {
        or: [
          {
            campName: {
              like: term,
              options: 'i',
            },
          },
          {
            _id: {
              like: term,
              options: 'i',
            },
          },
        ],
      }),
      ...(size(regions) > 0 &&
        !term && {
          countryRegionCode: {
            inq: regions,
          },
        }),
      ...(restOfWorldMode === true
        ? {}
        : {
            restOfWorld: {
              neq: true,
            },
          }),
      disabled: {
        neq: true,
      },
    },
    include: ['featuredImage'],
    limit,
    skip,
  };

  if (!searchMode || onlineMode) {
    query.where = {
      ...query.where,
      ...(onlineMode === true
        ? {
            online: {
              eq: true,
            },
          }
        : {}),
    };
  }
  return query;
};

const useRegionKeys = () => {
  const selectedRegionsMap = useBuilderStore(state => state.ui.countries.selectedMap);
  return keys(selectedRegionsMap).filter(key => key !== 'all');
};

export const useSearchQuery = () => {
  const Api = useApi();
  const term = useBuilderStore(state => state.ui.accommodations.term);
  const onlineMode = useBuilderStore(state => state.ui.onlineMode);
  const restOfWorldMode = useBuilderStore(state => state.ui.restOfWorldMode);

  const searchMode = true;
  const countQuery = useAccommodationCountQuery(searchMode);
  const query = useInfiniteQuery({
    enabled: Boolean(term),
    queryKey: ['accommodations', 'list', { term, onlineMode, restOfWorldMode, searchMode }],
    queryFn: async ({ pageParam = 0 }) => {
      const response = await Api.getCamps(
        campsQuery(term, searchMode, onlineMode, restOfWorldMode, [], PAGE_LIMIT, pageParam * PAGE_LIMIT),
      );
      return response?.data || [];
    },
    getNextPageParam: (_lastPage, pages) => {
      const total = pages.reduce((acc, page) => (acc += size(page)), 0);
      return total >= countQuery?.data || countQuery?.data < PAGE_LIMIT ? undefined : Math.ceil(total / PAGE_LIMIT);
    },
  });
  return query;
};

export const useAccommodationCountQuery = searchMode => {
  const Api = useApi();
  const regions = useRegionKeys();
  const term = useBuilderStore(state => state.ui.accommodations.term);
  const onlineMode = useBuilderStore(state => state.ui.onlineMode);
  const restOfWorldMode = useBuilderStore(state => state.ui.restOfWorldMode);

  const query = useQuery({
    queryKey: ['accommodations', 'list', 'count', { regions, term, onlineMode, restOfWorldMode, searchMode }],
    queryFn: async () => {
      let countQuery = {
        ...(size(regions) > 0 &&
          !term && {
            countryRegionCode: {
              inq: regions,
            },
          }),
        ...(term && {
          campName: {
            like: term,
            options: 'i',
          },
        }),
        ...(restOfWorldMode === true
          ? {}
          : {
              restOfWorld: {
                neq: true,
              },
            }),
        disabled: {
          neq: true,
        },
      };
      if (!searchMode || onlineMode) {
        countQuery = {
          ...countQuery,
          ...(onlineMode === true
            ? {
                online: {
                  eq: true,
                },
              }
            : {}),
        };
      }
      const response = await Api.getCampsCount(countQuery);

      return response?.data?.count || 0;
    },
  });
  return query;
};

export const useCopyToBuilderQuery = () => {
  const Api = useApi();

  const camps = useBuilderStore(state => state.data.pinAccommodations);

  const query = useQuery({
    queryKey: ['accommodations', 'copyToBuilder', { camps }],
    queryFn: async () => {
      const response = await Api.getCamps(copyToBuilderQuery(camps));
      return response?.data || [];
    },
  });
  return query;
};

export const useCopyToBuilderOwnQuery = () => {
  const Api = useApi();

  const regions = useBuilderStore(state => state.data.ownAccommodations);

  const query = useQuery({
    queryKey: ['accommodations', 'copyToBuilderOwn', { regions }],
    queryFn: async () => {
      const response = await Api.getRegions({ where: { regionCode: { inq: regions }, disabled: { neq: true } } });
      return response?.data || [];
    },
  });
  return query;
};

export const useAccommodationsListQuery = () => {
  const Api = useApi();
  const regions = useRegionKeys();

  const term = useBuilderStore(state => state.ui.accommodations.term);
  const onlineMode = useBuilderStore(state => state.ui.onlineMode);
  const restOfWorldMode = useBuilderStore(state => state.ui.restOfWorldMode);

  // dependent query so we don't refetch every time
  const searchMode = false;
  const countQuery = useAccommodationCountQuery(searchMode);
  const query = useInfiniteQuery({
    enabled: !isUndefined(countQuery.data) && !term,
    queryKey: ['accommodations', 'list', { regions, onlineMode, restOfWorldMode }],
    queryFn: async ({ pageParam = 0 }) => {
      const response = await Api.getCamps(
        campsQuery('', searchMode, onlineMode, restOfWorldMode, regions, PAGE_LIMIT, pageParam * PAGE_LIMIT),
      );
      return response?.data || [];
    },
    getNextPageParam: (_lastPage, pages) => {
      const total = pages.reduce((acc, page) => (acc += size(page)), 0);
      return total >= countQuery?.data || countQuery?.data < PAGE_LIMIT ? undefined : Math.ceil(total / PAGE_LIMIT);
    },
  });
  return query;
};

export const useGetQuoteMutation = () => {
  const Api = useApi();
  const history = useHistory();
  const user = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const reset = useBuilderStore(state => state.actions.reset);
  const mutation = useCustomMutation(
    'get-quote',
    async itinerary => {
      const result = await Api.itinerarySave(itinerary);
      // @todo: data{} shouldn't be returned
      const itineraryId = result?.data?.id;
      const response = await Api.itineraryQuote(itineraryId, null, false);
      return response?.data;
    },
    {
      onSuccess: data => {
        // @hack reset() sets edit=null, which causes a redirect if you are on the /details route
        // this clobbers the redirect to itinerary builder
        // this _will_ go away when we move room selection to a modal in IB2+
        setTimeout(() => {
          history.push(user?.agency?.[FEATURE_FLAG.EDIT_ITINERARY_2] ? `/itinerary/${data?.id}` : `/itinerary/edit/${data?.id}`);
        }, 1);
        localStorage.removeItem(STORE_LOCAL_STORAGE_KEY);
        reset();
      },
      onError: () => {
        enqueueSnackbar('Unable to generate quote', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'bottom' } });
      },
    },
  );
  return mutation;
};

export const weekByWeekQueryKey = (supplierCode, dateString, adults, childrenAges = [], rooms = []) => {
  return ['availability', supplierCode, dateString, adults, ...childrenAges, ...rooms];
};

export const useWeekByWeekAvailabilityQuery = (supplierCode, date, adults, childrenAges, rooms = [], enabled) => {
  const Api = useApi();
  const isQuoteOnly = useQuoteOnly();
  const dateString = formatDate(date);
  return useQuery(weekByWeekDefaultQueryArgs(supplierCode, dateString, adults, childrenAges, rooms, Api, !isQuoteOnly, enabled));
};

export const weekByWeekDefaultQueryArgs = (supplierCode, dateString, adults, childrenAges, rooms, Api, _wait, enabled) => {
  return {
    queryKey: weekByWeekQueryKey(supplierCode, dateString, adults, childrenAges, rooms),
    queryFn: async ({ queryKey }) => {
      const supplierCode = queryKey[1];
      if (!supplierCode) return null; // Own arranged.
      const dateString = queryKey[2];
      const nextDayString = formatDate(addDays(parse(dateString, 'yyyy-MM-dd', new Date()), 7));
      return Api.calendarBySupplier(supplierCode, dateString, nextDayString, adults, childrenAges, rooms, true);
    },
    staleTime: 1000 * 60 * 10,
    cacheTime: 1000 * 60 * 30,
    keepPreviousData: true,
    enabled,
  };
};

export const useCampFeaturesMapQuery = () => {
  const Api = useApi();
  const query = useQuery({
    queryKey: ['camp-features', 'list'],
    queryFn: async () => {
      const response = await Api.getFeatures();
      return keyBy(response?.data || [], 'featureId');
    },
  });
  return query;
};

export const usePpQuery = (startDateString, supplierCode, enabled) => {
  const Api = useApi();
  const query = useQuery({
    queryKey: ['accommodation-price-overview', { supplierCode, startDateString }],
    queryFn: () => Api.supplierLowestPpRateForMonth(startDateString, supplierCode),
    enabled,
    staleTime: 1000 * 60 * 10,
    cacheTime: 1000 * 60 * 30,
    keepPreviousData: true,
  });
  return query;
};
