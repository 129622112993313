import { useQuery } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';

export const useInclusionsBySupplierQuery = (supplierCode, startDate, endDate) => {
  const Api = useApi();
  const query = useQuery({
    queryKey: [
      'itinerary-builder-inclusions',
      {
        supplierCode,
        startDate,
        endDate,
      },
    ],
    queryFn: () => Api.getInclusionsBySupplier([supplierCode], startDate, endDate),
  });
  return query;
};
