import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//TODO - can this live lower, should it live higher?
import { APIProvider } from '@vis.gl/react-google-maps';

import { useCampDetails } from '../hooks';
import { AccommodationsTabsEnum } from '../../enums';
import { Content, /*Debug,*/ Details, Video } from './tabs';
import LoadingOverlay from 'components/v2/LoadingOverlay';
import { NavigationTabs } from '../../components';
import { isSuperAdmin } from '../../../../utils/acls';
import makeStyles from '@mui/styles/makeStyles';

const tabs = [
  {
    label: 'Accommodations Details',
    value: AccommodationsTabsEnum.DETAILS,
    Component: Details,
  },
  {
    label: 'Accommodation Content',
    value: AccommodationsTabsEnum.CONTENT,
    Component: Content,
  },
  {
    label: 'Accommodation Video',
    value: AccommodationsTabsEnum.VIDEO,
    Component: Video,
    acl: isSuperAdmin,
  },
];

const useStyles = makeStyles(theme => ({
  contentContainer: {
    padding: theme.spacing(4, 0),
  },
}));

const AccommodationsEditor = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const supplierCode = params.supplierCode;
  const { campDetailsQuery, mediaContentQuery, campInfo } = useCampDetails(supplierCode);

  const onTabChange = useCallback(
    (_e, value) => {
      history.push(`/content-manager/accommodations/${supplierCode}/${value}/`);
    },
    [history, supplierCode],
  );

  if (!campDetailsQuery.isFetched || !campInfo || !mediaContentQuery.isFetched) {
    return <LoadingOverlay open={campDetailsQuery.isFetching} />;
  }
  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <Container className={classes.contentContainer}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
          <IconButton
            color="primary"
            className="hoverable"
            aria-label="nav content"
            onClick={() => history.push('/content-manager/accommodations/')}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h3">{campInfo.campName}</Typography>
        </div>
        <div style={{ paddingLeft: 40, marginBottom: 30 }}>
          <Typography variant="body">
            {campInfo?.regionName}, {campInfo?.country}
          </Typography>
        </div>
        <NavigationTabs onTabChange={onTabChange} defaultTab={AccommodationsTabsEnum.DETAILS} tabs={tabs} />
      </Container>
    </APIProvider>
  );
};

export default AccommodationsEditor;
