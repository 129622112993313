const Trash = () => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="Vector"
      d="M9.32434 11.7077C9.32434 11.9649 9.53474 12.1753 9.7919 12.1753C10.0491 12.1753 10.2595 11.9649 10.2595 11.7077V5.62935C10.2595 5.37219 10.0491 5.16178 9.7919 5.16178C9.53474 5.16178 9.32434 5.37219 9.32434 5.62935V11.7077ZM6.98651 11.7077C6.98651 11.9649 7.19691 12.1753 7.45407 12.1753C7.71123 12.1753 7.92164 11.9649 7.92164 11.7077V5.62935C7.92164 5.37219 7.71123 5.16178 7.45407 5.16178C7.19691 5.16178 6.98651 5.37219 6.98651 5.62935V11.7077ZM4.64867 11.7077C4.64867 11.9649 4.85908 12.1753 5.11624 12.1753C5.3734 12.1753 5.58381 11.9649 5.58381 11.7077V5.62935C5.58381 5.37219 5.3734 5.16178 5.11624 5.16178C4.85908 5.16178 4.64867 5.37219 4.64867 5.62935V11.7077ZM4.72173 0.747374L3.64925 2.35639H1.60949C1.22083 2.35639 0.908144 2.67053 0.908144 3.05774C0.908144 3.4464 1.22083 3.75908 1.60949 3.75908H1.84328V12.6428C1.84328 13.9345 2.88946 14.9807 4.18111 14.9807H10.727C12.0181 14.9807 13.0649 13.9345 13.0649 12.6428V3.75908H13.2987C13.6859 3.75908 14 3.4464 14 3.05774C14 2.67053 13.6859 2.35639 13.2987 2.35639H11.2583L10.1864 0.747374C9.88249 0.291993 9.37109 0.0185547 8.82463 0.0185547H6.08352C5.53705 0.0185547 5.02565 0.292023 4.72173 0.747374ZM9.57273 2.35639H5.33541L5.89065 1.52529C5.93448 1.46041 6.00754 1.42125 6.08352 1.42125H8.82463C8.90061 1.42125 8.99996 1.46041 9.0175 1.52529L9.57273 2.35639ZM11.6622 12.6428C11.6622 13.1601 11.2434 13.578 10.727 13.578H4.18111C3.66386 13.578 3.24598 13.1601 3.24598 12.6428V3.75908H11.6622V12.6428Z"
      fill={'currentColor' || 'black'}
    />
  </svg>
);
export default Trash;
