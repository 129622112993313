import { useQuery } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';
import Agency from 'models/Agency';

export const mediaContentKey = mediaContentId => ['mediaContent', mediaContentId];

export const useMediaContentQuery = ({ mediaContentId }) => {
  const Api = useApi();
  const query = useQuery({
    queryKey: mediaContentKey(mediaContentId),
    queryFn: async () => {
      const response = await Api.getAgency(mediaContentId);
      return new Agency(response?.data);
    },
    retry: (_failureCount, error) => {
      if (error?.response?.status === 404) return false;
      return true;
    },
    cacheTime: 0,
  });
  return query;
};

export const useMediaContentForAccommodation = supplierCode => {
  const Api = useApi();
  const query = useQuery({
    queryKey: [`${supplierCode}-carousel`],
    queryFn: async () => {
      const response = await Api.getMediaContentCarouselByCamp(supplierCode);
      return response.data;
    },
  });
  return query;
};
