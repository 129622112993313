import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'query-client';
import { useApi } from 'common/hooks/api';
import { AUDIT_TRAIL_ACTIONS } from 'itrvl-types';
import { cloneDeep } from 'lodash';
import Client from 'models/Client';

export const clientDetailsKey = clientId => ['client', 'details', clientId];
export const useClientDetailsQuery = ({ clientId }) => {
  const Api = useApi();
  const query = useQuery({
    queryKey: clientDetailsKey(clientId),
    queryFn: async () => {
      let response;
      let client;

      response = await Api.getClient(clientId);
      client = new Client(response?.data);

      return client;
    },
    retry: (_failureCount, error) => {
      // this is axios specific handling, but lets not retry on a 404;
      if (error?.response?.status === 404) return false;
      return true;
    },
    cacheTime: 0,
  });
  return query;
};

export const useClientUpdates = (clientId, _clientDataId) => {
  const Api = useApi();
  const query = useQuery({
    queryKey: ['client', 'updates', clientId],
    queryFn: async () => {
      const response = await Api.getClientUpdates({
        where: {
          clientId: {
            inq: [clientId].filter(Boolean),
          },
          action: {
            // hard coded for now, this can be updated if we need to use it in other places
            nin: [
              AUDIT_TRAIL_ACTIONS.AGENT_ACCESSED_CLIENT,
              AUDIT_TRAIL_ACTIONS.AGENT_ACCESSED_ITINERARY,
              AUDIT_TRAIL_ACTIONS.AGENT_UPDATED_CLIENT,
              AUDIT_TRAIL_ACTIONS.AGENT_ASSIGNED_AGENT,
              AUDIT_TRAIL_ACTIONS.SYSTEM_COST_CHANGE_ITINERARY,
            ],
          },
        },
        limit: 10,
        fields: ['id', 'clientId', 'itineraryId', 'agencyId', 'agentId', 'action', 'details', 'timestamp'],
        include: ['agency', 'agent', 'client', 'itinerary'],
        order: ['timestamp DESC', 'id DESC'],
      });
      return response?.data;
    },
    cacheTime: 0,
  });
  return query;
};

export const updateClientDetails = (clientId, updater) => {
  const key = clientDetailsKey(clientId);
  const prev = cloneDeep(queryClient.getQueryData(key).client._dataValues);
  const next = updater(prev);
  queryClient.setQueryData(key, new Client(next));
};
