import { Checkbox, FormControlLabel, Grid, Paper, Typography } from '@mui/material';

export const CampFeaturesEditor = ({ title, options, selected, onToggle }) => {
  return (
    <Grid item xs={12}>
      <Typography variant="body2" mb={1} sx={{ fontWeight: 500 }}>
        {title}
      </Typography>
      <Paper elevation={0} p={4}>
        <Grid container mb={4} p={4}>
          {options.map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    name={item.featureId}
                    checked={selected.includes(item.featureId)}
                    onChange={e => onToggle(item.featureId, e.target.checked)}
                  />
                }
                label={item.featureName}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Grid>
  );
};
