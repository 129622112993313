import * as Sentry from '@sentry/react';

import logger from 'itrvl-logger';
import { matchPath } from 'react-router-dom';

const log = logger(__filename);

export const setupSentry = (tag, history, routes) => {
  const dsn = tag === 'agent' ? process.env.REACT_APP_SENTRY_AGENT_DSN : process.env.REACT_APP_SENTRY_CLIENT_DSN;
  const environment = process.env.REACT_APP_ITRVL_ENV;

  // Don't setup sentry on integration
  if (environment === 'integration') return;

  log.debug(`Setup Sentry Browser, environment: ${environment}, dsn: ${dsn}`);
  if (dsn) {
    let tracesSampleRate, replaySampleRate;
    switch (environment) {
      case 'development':
        tracesSampleRate = 0.15;
        replaySampleRate = 1;
        break;
      default:
        tracesSampleRate = 0.15; // Default for production environments
        replaySampleRate = 0.1;
        break;
    }
    const config = {
      dsn,
      environment,
      release: process.env.REACT_APP_VERSION,
      integrations: [
        new Sentry.Replay({
          maskAllText: false,
          maskAllInputs: false,
          inlineImages: false,
          blockAllMedia: false,
          networkDetailAllowUrls: [/https:\/\/.*itrvl.com\/api/, 'http://localhost:3000/api', 'http://localhost:3002/api'],
        }),
        new Sentry.BrowserTracing({
          tracingOrigins: [
            'localhost',
            'localhost:3001',
            'http://localhost:3000',
            'http://127.0.0.1:3001',
            'http://localhost:3001',
            /cdn-static.*itrvl.com/,
            /api.*itrvl.com/,
            /.*itrvl.com/,
            /^\//,
          ],
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routes, matchPath),
          shouldCreateSpanForRequest: _url => {
            return true;
          },
          idleTimeout: 5000,
        }), // Needs "Access-Control-Allow-Headers: sentry-trace"
      ],
      tracesSampleRate,
      replaysSessionSampleRate: replaySampleRate,
      replaysOnErrorSampleRate: 1,
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
      debug: false,
    };
    Sentry.init(config);
    Sentry.configureScope(scope => {
      scope.setTag('package', tag);
    });
    Sentry.setTag('rrweb', 'yes');
  }
};
