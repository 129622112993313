import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import {
  IconButton,
  Dialog,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  DialogTitle as MuiDialogTitle,
  DialogContentText,
} from '@mui/material';
import sharedStyles from 'styles/shared';

const styles = theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: { display: 'flex' },
  heading: {
    margin: 0,
    padding: theme.spacing(2, 2, 0, 2),
  },
});

const useStyles = makeStyles({
  modal: {
    borderRadius: 0,
  },
});

export const ModalTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  const s = sharedStyles();
  return (
    <MuiDialogTitle className={classes.heading}>
      <div className={s.flexRowSpacing}>
        {children}

        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} size="large">
            <CloseIcon size="small" />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  );
});

export const ModalContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2, 2),
  },
}))(MuiDialogContent);

export const ModalActions = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

export const BasicModal = props => {
  const classes = useStyles();
  return (
    <Dialog
      {...props}
      classes={{
        root: classes.modal,
        paper: classes.modal,
      }}
    />
  );
};
export const ModalContentText = DialogContentText;
