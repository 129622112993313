import React, { Suspense, Component, useEffect, useContext } from 'react';
import { Route, Redirect, Switch, Link, useParams, withRouter } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import * as Sentry from '@sentry/react';

import { map, get } from 'lodash';

import { Money } from '@mui/icons-material';

import { isSuperAdmin, /*isSuperContentManager,*/ isAdmin, isAuthenticated, isContentManager } from './utils/acls';

import { ObjectiveProvider } from './context/objective';
import Loading from 'common/components/Loading';
import { ReactComponent as ItrvlLogo } from 'common/assets/itrvl_logo--white.svg';
import { ReactComponent as BrokenVehicle } from 'common/assets/brokenVehicle.svg';

import withUser from 'common/hocs/withUser';
import lazy from 'common/utils/lazy';
import { useApi } from 'common/hooks/api';
import { UserContext } from 'common/context';
import { AUDIT_TRAIL_ACTIONS, FEATURE_FLAG } from 'itrvl-types';
import ItineraryBuilderEntry from './components/ItineraryBuilder/ItineraryBuilderEntry';
import WrappedPage from 'components/v2/WrappedPage';
//TODO - either make lazy or move into routes for the ContentManager
import AccommodationsEditor from './pages/ContentManager/AccommodationsManager/AccommodationsEditor';
import logger from 'itrvl-logger';
import MyProfile from 'pages/MyProfile';
const log = logger(__filename);

const Config = lazy(() => import('common/components/Config'), {
  chunkName: 'Config',
});
const Clients = lazy(() => import('./components/Clients'), {
  chunkName: 'Clients',
});
const ContentManager = lazy(() => import('./pages/ContentManager'), { chunkName: 'ContentManager' });

const AccommodationsManager = lazy(() => import('./components/admin/AccommodationsManager'), { chunkName: 'AccommodationsManager' });
const RegionsManager = lazy(() => import('./components/admin/RegionsManager'), {
  chunkName: 'RegionsManager',
});
const CountryManager = lazy(() => import('./components/admin/CountryManager'), {
  chunkName: 'CountryManager',
});
const ActivitiesManager = lazy(() => import('./components/admin/ActivitiesManager'), { chunkName: 'ActivitiesManager' });
const Agents = lazy(() => import('./components/Agents'), {
  chunkName: 'Agents',
});
const Agencies = lazy(() => import('./components/Agencies'), {
  chunkName: 'Agencies',
});
const AgencyDetails = lazy(() => import('./components/AgencyDetails'), {
  chunkName: 'AgencyDetails',
});
const MyAgency = lazy(() => import('./pages/MyAgency'), { chunkName: 'MyAgency' });
const ClientDetails = lazy(() => import('./pages/Clients/ClientDetails'), {
  chunkName: 'ClientDetails',
});
const EditItinerary = lazy(() => import('./components/Itinerary/EditItinerary'), { chunkName: 'EditItinerary' });
const ItrvlAppBar = lazy(() => import('./components/ItrvlAppBar'), {
  chunkName: 'ItrvlAppBar',
});
const ItrvlHome = lazy(() => import('./components/Landing/ItrvlHome'), {
  chunkName: 'ItrvlHome',
});
const Efficient = lazy(() => import('./components/Landing/SubPages/Efficient'), { chunkName: 'Efficient' });
const StatusBar = lazy(() => import('./components/StatusBar'), {
  chunkName: 'StatusBar',
});
const SavedBuilder = lazy(() => import('./components/SavedItineraryBuilder/SavedBuilderEntry'), {
  chunkName: 'SavedBuilder',
});
const Builder = lazy(() => import('./components/Builder/Builder'), {
  chunkName: 'Builder',
});
const ContentUploader = lazy(() => import('./components/ContentUploader/ContentUploader'), { chunkName: 'ContentUploader' });
const ContentLibrary = lazy(() => import('./components/ContentLibrary/ContentLibraryEntry'), { chunkName: 'ContentLibrary' });

const MasqueradeEnded = lazy(() => import('./components/MasqueradeEnded'), {
  chunkName: 'MasqueradeEnded',
});
const WelcomeWizard = lazy(() => import('./components/WelcomeWizard/index'), {
  chunkName: 'WelcomeWizard',
});
const TripsAndPayments = lazy(() => import('./components/admin/TripsAndPayments'), { chunkName: 'TripsAndPayments' });
const TripDetails = lazy(() => import('./components/admin/TripDetails'), {
  chunkName: 'TripDetails',
});
const LoginScreen = lazy(() => import('./components/LoginScreen'), {
  chunkName: 'LoginScreen',
});
const Dashboard = lazy(() => import('./components/Dashboard'), {
  chunkName: 'Dashboard',
});
const Notifications = lazy(() => import('./components/Notifications'), {
  chunkName: 'Notifications',
});

// v2
const ItineraryBuilder = lazy(() => import('./components/ItineraryBuilder/ItineraryBuilderEntry'), { chunkName: 'ItineraryBuilder' });
const EditItineraryEntry = lazy(() => import('./components/EditItinerary/EditItineraryEntry'), { chunkName: 'EditItineraryV2' });
// presentation editor
const Presentation = lazy(() => import('./pages/Itinerary/ItineraryDetail/Presentation'), { chunkName: 'Presentation' });

const SentryRoute = Sentry.withSentryRouting(Route);
const notFoundStyle = makeStyles(
  {
    main: {
      color: 'white',
      backgroundColor: 'black',
      width: '100%',
      height: '100%',
      flex: 1,
    },
    header: {
      height: '69px',
    },
    blueBackground: {
      backgroundColor: '#639fc3',
    },
    body: {
      borderRadius: '5px',
      width: '80%',
      margin: '25% auto',
      padding: '10px',
      '& > div': {
        margin: '10px auto',
        textAlign: 'center',
      },
    },
    p1: {
      fontSize: '48px',
    },
    p2: {
      fontSize: '24px',
    },
    logo: {
      margin: '15px',
      height: '100px',
    },
  },
  { name: `${__filename}.notFoundStyle` },
);

const NotFound = () => {
  const classes = notFoundStyle();

  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <ItrvlLogo className={classes.logo} alt="itrvl Logo" />
      </div>
      <div className={clsx(classes.body, classes.blueBackground)}>
        <div>
          <BrokenVehicle alt="Broken Vehicle" />
        </div>
        <div className={classes.p1}>Oops! Something went wrong.</div>
        <div className={classes.p2}>
          You can try refreshing the page, go back, or <Link to="/">go home</Link>.
        </div>
      </div>
    </div>
  );
};

const TestRoute = ({ component: Component, ...rest }) => <SentryRoute {...rest} component={Component} />;
const SentryError = props => {
  const pretag = props.history.action === 'PUSH' ? '' : 'web-';
  return (
    <SentryRoute
      {...props}
      component={() => {
        throw new Error(`throw.sentry.${pretag}agent`);
      }}
    />
  );
};

const Content = ({ children }) => (
  <div
    style={{
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#F7F4F0',
    }}
  >
    {children}
  </div>
);

const useStyles = makeStyles(
  theme => ({
    root: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    '@global': {
      a: {
        color: theme.palette.secondary.main,
        textDecoration: 'none',
        transition: theme.transitions.create(['color']),
        '&:hover': {
          color: theme.palette.secondary.dark,
        },
      },
    },
  }),
  { name: 'routes' },
);

const ProtectedContent = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root} data-scroll-container>
      {children}
    </div>
  );
};

const AuditTrail = ({ logAudit, ...props }) => {
  const params = useParams();
  const { user } = useContext(UserContext);
  const Api = useApi();

  useEffect(() => {
    const sendLogAudit = async () => {
      const values = {
        params,
        user,
      };
      const { action = '', matchProps = {} } = logAudit ?? {};
      const idProps = Object.entries(matchProps).reduce((res, [key, value]) => {
        res[key] = get(values, value);
        return res;
      }, {});

      try {
        const { data } = await Api.logAudit({
          action,
          ...idProps,
        });
        log.debug(data);
      } catch (err) {
        log.error(err);
      }
    };

    if (logAudit) {
      try {
        sendLogAudit();
      } catch (err) {
        log.error(err);
      }
    }
  }, [Api, logAudit, params, user]);

  return <>{props.children}</>;
};

const ProtectedRoute = props => {
  const {
    logAudit,
    useWrappedPage = false,
    useV1Theme = false,
    useFixedAppbar = false,
    showObjectives = false,
    isAccessAllowed,
    component: Component,
    ...rest
  } = props;
  log.debug('Protected Route:', Component.name);

  return (
    <SentryRoute
      {...rest}
      render={props =>
        isAccessAllowed ? (
          <React.Fragment>
            <AuditTrail logAudit={logAudit}>
              {useWrappedPage ? (
                <ProtectedContent>
                  <Suspense fallback={<Loading />}>
                    <WrappedPage page={Component} useFixedAppbar={useFixedAppbar} useV1Theme={useV1Theme} {...props} />
                  </Suspense>
                </ProtectedContent>
              ) : (
                <>
                  <ItrvlAppBar />
                  <ProtectedContent>
                    <Suspense fallback={<Loading />}>
                      <Component {...props} />
                    </Suspense>
                  </ProtectedContent>
                </>
              )}
              {showObjectives && (
                <ObjectiveProvider>
                  <WelcomeWizard />
                </ObjectiveProvider>
              )}
              <StatusBar />
            </AuditTrail>
          </React.Fragment>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const BareProtectedRoute = props => {
  const { logAudit, showObjectives = false, isAccessAllowed, component: Component, ...rest } = props;
  log.debug('Bare Protected Route:', Component.name);

  return (
    <SentryRoute
      {...rest}
      render={props =>
        isAccessAllowed ? (
          <React.Fragment>
            <AuditTrail logAudit={logAudit}>
              <Suspense fallback={<Loading />}>
                <Component {...props} />
              </Suspense>
              {showObjectives && (
                <ObjectiveProvider>
                  <WelcomeWizard />
                </ObjectiveProvider>
              )}
            </AuditTrail>
          </React.Fragment>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const AdminRoute = ({ userContext: { user }, ...rest }) => {
  log.trace('AdminRoute');
  return <ProtectedRoute user={user} isAccessAllowed={isAdmin(user)} showObjectives {...rest} />;
};
/*
const SuperContentManagerRoute = ({ userContext: { user }, ...rest }) => {
  log.trace('SuperContentManagerRoute');
  return <ProtectedRoute isAccessAllowed={isSuperContentManager(user)} showObjectives={false} {...rest} />;
};
*/
const ContentManagerRoute = ({ userContext: { user }, ...rest }) => {
  log.trace('ContentManagerRoute');
  return <ProtectedRoute isAccessAllowed={isContentManager(user)} showObjectives={false} {...rest} />;
};

const SuperAdminRoute = ({ userContext: { user }, ...rest }) => {
  log.trace('SuperAdminRoute');
  return <ProtectedRoute isAccessAllowed={isSuperAdmin(user)} showObjectives={false} {...rest} />;
};

const AuthenticatedRoute = ({ userContext: { user }, allowsGuest, useBarePage, ...rest }) => {
  log.trace('AuthenticatedRoute');
  if (useBarePage) return <BareProtectedRoute isAccessAllowed={isAuthenticated(user, allowsGuest)} showObjectives {...rest} />;
  return <ProtectedRoute isAccessAllowed={isAuthenticated(user, allowsGuest)} showObjectives {...rest} />;
};

const NotAuthenticatedOrRedirect = ({ component: Component, authenticatedRedirect, userContext: { user }, allowsGuest, ...rest }) => {
  log.debug('NotAuthentitedOrRedirect', Component.name);

  return (
    <SentryRoute
      {...rest}
      render={props =>
        isAuthenticated(user, allowsGuest) ? (
          <Redirect
            to={{
              pathname: authenticatedRedirect,
              state: { from: props.location },
            }}
          />
        ) : (
          <Suspense fallback={<Loading />}>
            <Component {...props} />
          </Suspense>
        )
      }
    />
  );
};

// Exposed for SSR render and SiteMenu
export const routes = [
  {
    name: 'sentryError',
    path: '/sentryError',
    exact: true,
    component: SentryError,
    dev: true,
  },
  // temp route for dev (CS)
  {
    name: 'home',
    exact: true,
    authenticated: true,
    useWrappedPage: true,
    path: '/',
    component: ItrvlHome,
    authenticatedRedirect: '/dashboard',
  },
  {
    name: 'Itinerary Builder',
    exact: true,
    authenticated: true,
    useWrappedPage: true,
    path: '/itinerary-builder',
    component: ItineraryBuilder,
  },
  {
    name: 'subpages',
    exact: true,
    path: ['/productive', '/powerful', '/profitable'],
    component: Efficient,
  },
  {
    name: 'Notifications',
    path: '/notifications',
    component: Notifications,
    exact: true,
    authenticated: true,
    useWrappedPage: true,
  },
  {
    name: 'Dashboard',
    exact: true,
    authenticated: true,
    path: '/dashboard',
    component: Dashboard,
    useWrappedPage: true,
    menu: true,
    icon: 'RefNumber',
  },
  {
    name: 'My Profile',
    exact: true,
    authenticated: true,
    useWrappedPage: true,
    path: '/my-profile',
    component: MyProfile,
    menu: true,
    icon: 'myProfile',
  },
  {
    name: 'Agents Profile',
    exact: true,
    authenticated: true,
    useWrappedPage: true,
    path: '/agents/:id',
    component: MyProfile,
    menu: false,
  },
  {
    name: 'login',
    path: '/:route(login|forgot|forgotten|loggedOut|reset|register|registered|verified)/:token?',
    component: LoginScreen,
  },
  {
    name: 'verify',
    path: '/:route(verify)/:uid/:token',
    component: LoginScreen,
  },
  {
    name: 'Content Library',
    exact: true,
    path: '/content',
    component: ContentLibrary,
    contentmanager: true,
    useWrappedPage: true,
    menu: true,
    icon: 'categories',
  },
  {
    name: 'uploads',
    exact: true,
    authenticated: true,
    path: '/content/uploads',
    useWrappedPage: true,
    useV1Theme: true,
    component: ContentUploader,
  },
  {
    name: 'Itinerary Builder',
    exact: true,
    authenticated: true,
    path: '/itinerary-builder',
    menu: true,
    new: true,
    icon: 'planner',
    component: ItineraryBuilderEntry,
    useWrappedPage: true,
  },
  {
    name: 'Build Itinerary',
    exact: true,
    authenticated: true,
    path: ['/builder', '/custom'],
    component: Builder,
    menu: true,
    legacy: true,
    icon: 'itinerary',
    useV1Theme: true,
    useWrappedPage: true,
  },
  {
    name: 'Saved Itineraries',
    exact: true,
    authenticated: true,
    path: ['/saved', '/saved/:id/:component?'],
    component: SavedBuilder,
    menu: true,
    icon: 'itinerary',
    useWrappedPage: true,
  },
  {
    name: 'edit',
    exact: true,
    authenticated: true,
    useWrappedPage: true,
    useV1Theme: true,
    path: '/itinerary/edit/:id',
    component: EditItinerary,
    logAudit: {
      action: AUDIT_TRAIL_ACTIONS.AGENT_ACCESSED_ITINERARY,
      matchProps: {
        itineraryId: 'params.id',
        agentId: 'user.userId',
      },
    },
  },
  {
    name: 'Itinerary',
    exact: true,
    authenticated: true,
    useWrappedPage: true,
    path: '/itinerary/:id',
    component: EditItineraryEntry,
  },
  {
    name: 'Itinerary',
    exact: true,
    authenticated: true,
    useWrappedPage: true,
    path: '/itinerary/:id/:edit(edit-transit|edit-activities)',
    component: EditItineraryEntry,
  },
  {
    name: 'PresentationRedirect',
    exact: true,
    relative: true,
    from: '/itinerary/:id/presentation',
    to: '/welcome',
    redirect: true,
  },
  {
    name: 'Presentation',
    path: '/itinerary/:id/presentation',
    authenticated: true,
    useWrappedPage: true,
    useFixedAppbar: true,
    component: Presentation,
  },
  {
    name: 'My Agents',
    exact: true,
    path: '/agents',
    component: Agents,
    admin: true,
    menu: true,
    useWrappedPage: true,
    icon: 'onRequest',
  },
  {
    name: 'My Agency',
    exact: true,
    path: '/my-agency',
    component: MyAgency,
    admin: true,
    useWrappedPage: true,
    menu: true,
    icon: 'settings',
  },
  {
    name: 'agency',
    exact: true,
    superadmin: true,
    useWrappedPage: true,
    useV1Theme: true,
    path: '/agencies/:id',
    component: AgencyDetails,
  },
  {
    name: 'All Agencies',
    exact: true,
    superadmin: true,
    useWrappedPage: true,
    useV1Theme: true,
    path: '/agencies',
    component: Agencies,
    menu: true,
    icon: 'edit',
  },
  {
    name: 'My Clients',
    exact: true,
    authenticated: true,
    useWrappedPage: true,
    path: '/clients',
    component: Clients,
    menu: true,
    icon: 'profile',
  },
  {
    name: 'agentsClients',
    exact: true,
    authenticated: true,
    path: '/agents/:agentId/clients',
    component: Clients,
  },
  {
    name: 'client',
    exact: true,
    authenticated: true,
    useWrappedPage: true,
    path: '/clients/:id',
    component: ClientDetails,
    logAudit: {
      action: AUDIT_TRAIL_ACTIONS.AGENT_ACCESSED_CLIENT,
      matchProps: {
        clientId: 'params.id',
        agentId: 'user.userId',
      },
    },
    menu: false,
  },
  {
    name: 'masquerade-end',
    path: '/masquerade-end',
    exact: true,
    component: MasqueradeEnded,
    authenticated: true,
  },
  {
    name: 'Trips and Payments',
    path: '/trips-and-payments',
    exact: true,
    component: TripsAndPayments,
    authenticated: true,
    menu: true,
    useWrappedPage: true,
    Icon: Money,
  },
  {
    name: 'tripDetails',
    path: '/itinerary/details/:id',
    exact: true,
    component: TripDetails,
    authenticated: true,
    useWrappedPage: true,
    useV1Theme: true,
  },
  {
    name: 'accommodationsManager',
    path: '/AccommodationsManager/:importId',
    exact: true,
    component: AccommodationsManager,
    useWrappedPage: true,
    contentmanager: true,
  },
  {
    name: 'Accommodations Manager',
    path: '/AccommodationsManager',
    exact: true,
    component: AccommodationsManager,
    contentmanager: true,
    useWrappedPage: true,
    menu: true,
    icon: 'camp',
  },
  {
    name: 'regionsManager',
    path: '/RegionsManager/:importId',
    exact: true,
    component: RegionsManager,
    useWrappedPage: true,
    contentmanager: true,
  },
  {
    name: 'Regions Manager',
    path: '/RegionsManager',
    exact: true,
    component: RegionsManager,
    contentmanager: true,
    useWrappedPage: true,
    menu: true,
    icon: 'africa',
  },
  {
    name: 'countryManagerImport',
    path: '/CountryManager/:importId',
    exact: true,
    component: CountryManager,
    contentmanager: true,
  },
  {
    name: 'Country Manager',
    path: '/CountryManager',
    exact: true,
    component: CountryManager,
    contentmanager: true,
    menu: true,
    useWrappedPage: true,
    icon: 'group',
  },
  {
    name: 'activitesManagerImport',
    path: '/ActivitiesManager/:importId',
    exact: true,
    component: ActivitiesManager,
    contentmanager: true,
  },
  {
    name: 'Activities Manager',
    path: '/ActivitiesManager',
    exact: true,
    component: ActivitiesManager,
    contentmanager: true,
    useWrappedPage: true,
    menu: true,
    icon: 'experiences',
  },
  {
    name: 'Content Manager',
    path: '/content-manager/:tab(accommodations|services|countries|regions)?',
    exact: true,
    component: ContentManager,
    useWrappedPage: true,
    contentmanager: true,
    flag: user =>
      user?.agency?.[FEATURE_FLAG.CONTENT_MANGER_V2] === true &&
      !!(user?.capabilities.includes('contentmanager') || user?.capabilities.includes('superadmin')),
  },
  //Added twice, once for the menu so it doesn't get optional routing params
  //TODO - flag kind of hacks the ui to no be visible where contentmanager guards against access
  {
    name: 'Accommodation Manager v2',
    path: '/content-manager/accommodations',
    exact: true,
    component: AccommodationsEditor,
    useWrappedPage: true,
    contentmanager: true,
    menu: true,
    icon: 'camp',
    flag: user =>
      user?.agency?.[FEATURE_FLAG.CONTENT_MANGER_V2] === true &&
      !!(user?.capabilities.includes('contentmanager') || user?.capabilities.includes('superadmin')),
  },
  {
    name: 'Accommodation Manager',
    path: '/content-manager/accommodations/:supplierCode/:tab(details|content|video|debug)?',
    exact: true,
    component: AccommodationsEditor,
    useWrappedPage: true,
    contentmanager: true,
  },

  {
    name: 'config',
    path: '/config',
    exact: true,
    component: Config,
    superadmin: true,
  },
  // This should always be last
  {
    name: 'NotFound',
    path: '*',
    component: NotFound,
  },
];
/*
else if (route.supercontentmanager) {
  ret = <SuperContentManagerRoute key={route.name} {...props} {...route} />;
}
* */
function buildRoute(route, props) {
  let ret;
  if (route.test) {
    return <TestRoute key={route.name} {...props} {...route} />;
  }
  if (route.admin) {
    ret = <AdminRoute key={route.name} {...props} {...route} />;
  } else if (route.contentmanager) {
    ret = <ContentManagerRoute key={route.name} {...props} {...route} />;
  } else if (route.superadmin) {
    ret = <SuperAdminRoute key={route.name} {...props} {...route} />;
  } else if (route.authenticated) {
    ret = route.authenticatedRedirect ? (
      <NotAuthenticatedOrRedirect key={route.name} {...props} {...route} />
    ) : (
      <AuthenticatedRoute key={route.name} {...props} {...route} />
    );
  } else if (route.authenticated && route.authenticatedComponent) {
    ret = <AuthenticatedRoute key={route.name} {...props} {...route} />;
  } else if (route.redirect) {
    const { pathname } = props.location;
    const pathWithoutTrailingSlash = pathname[pathname.length - 1] === '/' ? pathname.slice(0, -1) : pathname;
    ret = (
      <Redirect
        key={route.name}
        {...props}
        exact={!!route.exact}
        from="/itinerary/:id/presentation"
        to={{
          pathname: route.relative ? `${pathWithoutTrailingSlash}${route.to}` : route.to,
          state: { from: props.location },
        }}
      />
    );
  } else {
    ret = <SentryRoute key={route.name} {...props} {...route} />;
  }
  return ret;
}

class Routes extends Component {
  render() {
    log.debug('Routes');
    return (
      <Content>
        <Suspense fallback={<Loading />}>
          <Switch>{map(routes, route => buildRoute(route, this.props))}</Switch>
        </Suspense>
      </Content>
    );
  }
}

export default withRouter(withUser(Routes));
