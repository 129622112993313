import { useQuery } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';

export const useExchangeRatesQuery = (currency, enabled = true) => {
  const Api = useApi();
  const query = useQuery({
    queryKey: [
      'exchange-rates',
      {
        currency,
      },
    ],
    queryFn: async () => {
      // Always update USD since we use that in tons of places
      const { data: exchangeUsd } = await Api.getExchangeRates('USD');
      const exchange = { USD: exchangeUsd };
      if (currency && currency !== 'USD') {
        const { data: currencyRates } = await Api.getExchangeRates(currency);
        exchange[currency] = currencyRates;
      }
      return exchange;
    },
    enabled,
  });
  return query;
};
