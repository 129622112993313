const Upload = ({ color }) => (
  <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.13672 5.8125H3.75V8.98438C3.75 9.64062 4.29688 10.1875 4.95312 10.1875H6.01953C6.67578 10.1875 7.25 9.64062 7.25 8.98438V5.8125H8.83594C9.24609 5.8125 9.60156 5.59375 9.76562 5.21094C9.92969 4.82812 9.875 4.39062 9.57422 4.08984L6.23828 0.453125C6.04688 0.261719 5.77344 0.125 5.5 0.125C5.19922 0.125 4.92578 0.261719 4.73438 0.453125L1.39844 4.08984C1.09766 4.39062 1.04297 4.82812 1.20703 5.21094C1.37109 5.59375 1.72656 5.8125 2.13672 5.8125ZM2.02734 4.69141L5.36328 1.05469C5.41797 1 5.55469 1 5.58203 1.05469L8.94531 4.69141C9 4.74609 9 4.80078 8.97266 4.85547C8.94531 4.91016 8.89062 4.9375 8.83594 4.9375H6.375V8.98438C6.375 9.17578 6.21094 9.3125 6.01953 9.3125H4.95312C4.76172 9.3125 4.625 9.17578 4.625 8.98438V4.9375H2.13672C2.08203 4.9375 2.02734 4.91016 2 4.85547C1.97266 4.80078 2 4.74609 2.02734 4.69141ZM0.6875 12.375H10.3125C10.5312 12.375 10.75 12.1836 10.75 11.9375C10.75 11.7188 10.5312 11.5 10.3125 11.5H0.6875C0.441406 11.5 0.25 11.7188 0.25 11.9375C0.25 12.1836 0.441406 12.375 0.6875 12.375Z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default Upload;
