import { useEffect } from 'react';
import {
  useCampsListQuery,
  useCampInfoEditsPatch,
  useCampInfoEditsUpsert,
  useCampFeaturesListQuery,
  useMediaContentForAccommodation,
  useCampAssemblerPut,
  useCampsAssemblerVideo,
} from '../../../queries';
import { useCampsStore } from './store';
import { useSnackbar } from 'notistack';
import { useUser } from 'common/hooks/user';
import { useQueryClient } from '@tanstack/react-query';

const commonSnackbarProps = {
  anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
  className: 'network-snack',
};

export const useCampsList = filters => {
  const campsQuery = useCampsListQuery({ filters });

  const { camps, count, setCamps, setCount } = useCampsStore();

  useEffect(() => {
    if (campsQuery.isFetched) {
      setCount(campsQuery.data.count);
      setCamps(campsQuery.data.data);
    }
  }, [campsQuery.isFetched, campsQuery.data, setCamps, setCount]);

  return {
    campsQuery,
    camps,
    count,
  };
};
// TODO - hoist some logic into a Context or maybe react-singleton-hook, for now api seems be to debounced but dont want an out of sync state
export const useCampDetails = id => {
  const { user } = useUser();
  const agencyId = user?.agency?.id ?? '';
  const { setCamp, setActivitiesAndAmenities, setMediaContent, campInfo, activities, amenities, mediaContent } = useCampsStore();

  const { enqueueSnackbar } = useSnackbar();
  const campDetailsQuery = useCampsListQuery({
    filters: {
      id: id,
      limit: 1,
      fields: [
        '_id',
        'adminEdits',
        'agencyEdits',
        'campFeatures',
        'campInfo',
        'campName',
        'country',
        'countryCode',
        'countryRegionCode',
        'flag',
        'flagDescription',
        'internalNote',
        'internalNoteColor',
        'mediaContent',
        'latitude',
        'longitude',
        'preferred',
        'regionCode',
        'regionName',
        'restOfWorld',
        'supplierCode',
      ],
    },
  });
  const featuresQuery = useCampFeaturesListQuery({});
  const mediaContentQuery = useMediaContentForAccommodation(id);
  const queryClient = useQueryClient();
  // make sure to load the new list with edits
  const resetCampsList = async () => {
    await queryClient.clear();
  };

  const patchMutation = useCampInfoEditsUpsert({
    onSuccess: async () => {
      enqueueSnackbar('Accommodation updated.', {
        variant: 'success',
        ...commonSnackbarProps,
      });
      await resetCampsList();
    },
    onError: () => {
      enqueueSnackbar('An error occurred while updating the accommodation.', {
        variant: 'error',
        ...commonSnackbarProps,
      });
    },
  });

  const resetMutation = useCampInfoEditsPatch({
    onSuccess: async () => {
      enqueueSnackbar('Accommodation has reset to default details.', {
        variant: 'success',
        ...commonSnackbarProps,
      });
      await campDetailsQuery.refetch();
      await resetCampsList();
    },
    onError: () => {
      enqueueSnackbar('An error occurred while resetting the accommodation.', {
        variant: 'error',
        ...commonSnackbarProps,
      });
    },
  });

  const save = async (id, payload) => {
    await patchMutation.mutateAsync({ id: getPatchId(id), payload: { ...payload, agencyId } });
  };

  const reset = async (id, payload) => {
    await resetMutation.mutateAsync({ id: getPatchIdString(id), payload });
  };

  //TODO - this should be moved to a utils for other edits
  //TODO - this is where agency id is getting mismatched
  const getPatchIdString = id => `${user?.agency?.agencyCode === 'ITR006' ? 'CONTENT_TEAM' : user?.agency?.id}-${id}`;
  const getPatchId = id => {
    return { _id: getPatchIdString(id) };
  };

  useEffect(() => {
    if (campDetailsQuery.isFetched) {
      setCamp(campDetailsQuery.data.data[0]);
    }
  }, [campDetailsQuery.isFetched, campDetailsQuery.data, setCamp]);

  useEffect(() => {
    if (featuresQuery.isFetched) {
      setActivitiesAndAmenities(featuresQuery.data);
    }
  }, [featuresQuery.isFetched, featuresQuery.data, setActivitiesAndAmenities]);

  useEffect(() => {
    if (mediaContentQuery.isFetched) {
      setMediaContent(mediaContentQuery?.data ?? []);
    }
  }, [mediaContentQuery.isFetched, mediaContentQuery?.data, setMediaContent]);

  return {
    campDetailsQuery,
    mediaContentQuery,
    save,
    reset,
    getPatchId,
    campInfo,
    activities,
    amenities,
    mediaContent,
  };
};

export const useVideoAssemblerUpload = id => {
  const { videoAssembler, setVideoAssembler } = useCampsStore();
  const { enqueueSnackbar } = useSnackbar();

  const assemblerVideoQuery = useCampsAssemblerVideo(id);
  //URL is good for ten minutes, so refresh ever eight
  useEffect(() => {
    const refreshTimer = setInterval(assemblerVideoQuery.refetch, 1000 * 60 * 8);
    return () => {
      clearInterval(refreshTimer);
    };
  }, [assemblerVideoQuery]);

  const uploadAssemblerVideo = async file => {
    await uploadCampAssemblerVideoMutation.mutateAsync({ s3PresignedUrl: videoAssembler.putUrl, file });
  };

  const uploadCampAssemblerVideoMutation = useCampAssemblerPut({
    onSuccess: async () => {
      enqueueSnackbar('Camp assembler video successfully uploaded to S3.', {
        variant: 'success',
        ...commonSnackbarProps,
      });
      setTimeout(assemblerVideoQuery.refetch, 1000);
    },
    onError: () => {
      enqueueSnackbar('An error occurred while uploading the assembler video to S3.', {
        variant: 'error',
        ...commonSnackbarProps,
      });
    },
  });

  useEffect(() => {
    if (assemblerVideoQuery.isFetched) {
      setVideoAssembler(assemblerVideoQuery.data);
    }
  }, [assemblerVideoQuery.isFetched, assemblerVideoQuery.data, setVideoAssembler]);

  return {
    uploadAssemblerVideo,
    videoAssembler,
  };
};
