import { Tabs } from '@mui/material';
import { withStyles } from '@mui/styles';

export default withStyles({
  root: {
    borderBottom: '1px solid #BEBCB7',
  },
  indicator: {
    backgroundColor: '#FF8B33',
  },
})(Tabs);
