export default function getCampEndpoints(build) {
  return {
    getCamp: build.query({
      query: id => `/Camps/${id}/camp-info`,
      providesTags: ['Camp'],
    }),
    upsertCamp: build.mutation({
      query: updateObj => ({
        url: `/Camps/upsertWithWhere`,
        method: 'POST',
        body: updateObj,
      }),
      invalidatesTags: ['Camp'],
    }),
    getAccommodation: build.query({
      query: supplierCode => `/Camps/${supplierCode}/accommodation-info/`,
    }),
  };
}
