import { useApi } from 'common/hooks/api';
import { useQuery } from '@tanstack/react-query';

export const useCampFeaturesListQuery = ({ enabled = true }) => {
  const Api = useApi();
  const query = useQuery({
    queryKey: ['camp-features-list'],
    queryFn: async () => {
      const response = await Api.getFeatures();
      return response?.data ?? [];
    },
    staleTime: Number.POSITIVE_INFINITY,
    enabled,
  });
  return query;
};
