import { Button, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useVideoAssemblerUpload } from '../../hooks';
import { useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { debounce } from 'lodash';
import LoadingOverlay from '../../../../../components/v2/LoadingOverlay';

export const Video = () => {
  const params = useParams();
  const supplierCode = params.supplierCode;

  const { videoAssembler, uploadAssemblerVideo } = useVideoAssemblerUpload(supplierCode);
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDrop = useCallback(
    debounce(async acceptedFiles => {
      try {
        setLoading(true);
        //TODO debounce
        await uploadAssemblerVideo(acceptedFiles[0]);
      } catch {
        //error handling in hook
      } finally {
        setLoading(false);
      }
    }, 250),
    [uploadAssemblerVideo, setLoading],
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'video/quicktime',
    onDrop,
    multiple: false,
  });
  // TODO - its tasks a bit for s3 to correctly reflect the name video,
  return (
    <>
      <LoadingOverlay open={loading} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body1" mb={4}>
          Accommodation Video
        </Typography>
        <div>
          <Button variant="outlined" {...getRootProps()} disabled={loading}>
            Add Video
          </Button>
          <input {...getInputProps()} data-test-id="video-input" />
        </div>
      </div>
      {videoAssembler && videoAssembler.getUrl && (
        <div>
          <video
            style={{ maxWidth: '100%', height: 'auto' }}
            src={videoAssembler.getUrl}
            controls
            crossOrigin="anonymous"
            data-test-id="video-container"
          />
        </div>
      )}
    </>
  );
};
