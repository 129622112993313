import axios from 'axios';

export default function getMediaContentEndpoints(build) {
  return {
    getMediaContentForItinerary: build.query({
      query: ({ itineraryId, presentationCategory, fkKey }) => {
        const filter = {
          where: {
            itineraryId: itineraryId,
            presentationCategory,
          },
        };
        if (fkKey) {
          filter.where.presentationFkKey = fkKey;
        }
        return `/MediaContent/?filter=${JSON.stringify(filter)}`;
      },
      providesTags: ['MediaContent'],
    }),

    getMediaContentForClient: build.query({
      query: ({ clientId, presentationCategory }) => {
        const filter = {
          where: {
            clientId,
            presentationCategory,
          },
        };
        return `/MediaContent/?filter=${JSON.stringify(filter)}`;
      },
      providesTags: ['MediaContent'],
    }),

    updateMediaContent: build.mutation({
      queryFn: async (mediaContents, _queryApi, _extraOptions, baseQuery) => {
        const promises = mediaContents.map(mediaContent => {
          const allowedKeys = ['presentationVisibility', 'status', 'agencyContent', 'categories'];
          const body = Object.keys(mediaContent).reduce((agg, key) => {
            if (allowedKeys.includes(key)) {
              agg[key] = mediaContent[key];
            }
            return agg;
          }, {});
          const { id } = mediaContent;
          return baseQuery({
            url: `/MediaContent/${id}`,
            method: 'PATCH',
            body,
          });
        });
        const res = await Promise.all(promises);
        const data = res.map(result => result.data);
        const errors = res.filter(result => !!result?.error).map(result => result.error);
        if (errors.length > 0) {
          return { error: errors };
        }
        return { data };
      },
    }),

    uploadMedia: build.mutation({
      queryFn: async ({ files, mediaMetadata }, _queryApi, _extraOptions, baseQuery) => {
        const promises = files.map(file => {
          const { name: userFilename, size, type: mimeType } = file;
          return baseQuery({
            url: '/MediaContent',
            method: 'POST',
            body: {
              ...mediaMetadata,
              userFilename,
              size,
              mimeType,
            },
          });
        });
        const res = await Promise.all(promises);
        const data = res.map(result => result.data);
        const errors = res.filter(result => !!result?.error).map(result => result.error);
        if (errors.length > 0) {
          return { error: errors };
        }
        return { data };
      },
    }),

    uploadToS3: build.mutation({
      queryFn: async (files, _queryApi, _extraOptions, _baseQuery) => {
        const promises = files.map(file =>
          axios.put(file.data.s3PresignedUrl, file.file, {
            headers: { 'Content-Type': file.file.type },
            // onUploadProgress: onProgress,
          }),
        );
        await Promise.all(promises);
        return { data: 'success' };
      },
      invalidatesTags: ['MediaContent'],
    }),

    deleteMediaContent: build.mutation({
      query: mediaContentId => ({
        url: `/MediaContent/${mediaContentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['MediaContent'],
    }),
  };
}
