export const ContentTabsEnum = {
  ACCOMMODATIONS: 'accommodations',
  SERVICES: 'services',
  COUNTRIES: 'countries',
  REGIONS: 'regions',
};

export const AccommodationsTabsEnum = {
  DETAILS: 'details',
  CONTENT: 'content',
  VIDEO: 'video',
  DEBUG: 'debug',
};
