import { CircularProgress } from '@mui/material';
import Day from './Day';
import { GridDateSlot } from './GridComponents';
import { formatDate } from './utils';

export default function GridSupplierCalendar({ supplierCode = '', camp = {}, overrides = {}, dates = [], inView }) {
  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      {inView ? (
        dates?.map(date => {
          const dateString = formatDate(date);
          return (
            <GridDateSlot key={dateString}>
              <Day supplierCode={supplierCode} dateString={dateString} data={camp} overrides={overrides} />
            </GridDateSlot>
          );
        })
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}
