import { Grid, Typography } from '@mui/material';
import { Fade } from '@mui/material';

import sharedStyles from 'styles/shared';
import Button from 'components/v2/Button';

export default function FormButtons({ isDirty, hasNoEdits, handleReset, handleCancel }) {
  const s = sharedStyles();

  return (
    <Grid item xs={12}>
      <div className={s.flexRowSpaced}>
        <Typography variant="body1"></Typography>
        <div>
          <Fade in={isDirty}>
            <span style={{ fontWeight: 500, color: 'var(--secondary-color)', paddingRight: 16 }}>* You have unsaved changes</span>
          </Fade>
          <Button disabled={hasNoEdits} size="small" onClick={handleReset} variant="outlined" className={s.mrXs}>
            Reset to Defaults
          </Button>
          <Button size="small" onClick={handleCancel} disabled={!isDirty} variant="outlined" className={s.mrXs}>
            Cancel
          </Button>
          <Button size="small" type="submit" disabled={!isDirty} variant="contained" color="primary">
            Save
          </Button>
        </div>
      </div>
    </Grid>
  );
}
