import { useWatch } from 'react-hook-form';
import InputRhf from './InputRhf';
import { Typography } from '@mui/material';

const InputLimitRhf = ({ control, limit, name, ...rest }) => {
  const flag = useWatch({ control, name }) || '';
  return (
    <>
      <InputRhf control={control} name={name} inputProps={{ maxLength: limit }} {...rest} />
      {/* negative margin hack because the form element saves room for an error */}
      <div style={{ display: 'flex', justifyContent: 'end', width: '100%', marginTop: -15 }}>
        <Typography variant="body2" color="#9e9e9e">
          {flag.length}/{limit}
        </Typography>
      </div>
    </>
  );
};

export default InputLimitRhf;
