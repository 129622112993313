const { useEffect, useState } = require('react');

export default function DeferredRender({ children }) {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let rafId;

    const deferRender = () => {
      rafId = window.requestAnimationFrame(() => {
        setShouldRender(true);
      });
    };

    deferRender();

    return () => {
      if (rafId) {
        window.cancelAnimationFrame(rafId);
      }
    };
  }, []);

  return shouldRender ? <>{children}</> : null;
}
