import moment from 'moment';
import { SEGMENT_TYPE } from './segmentTypes';
import { map, get, size, reduce, cloneDeep, isUndefined } from 'lodash';

import { PAYMENT_TYPE, getPaymentDueDate } from './payment';

import logger from 'itrvl-logger';
const log = logger(__filename);
export const getFromEnv = (name, def) => get(process.env, name, get(process.env, `REACT_APP_${name}`, def));

export const DEFAULT_DUE_DATE_LEAD_DAYS = 84;
export const OWN_ACCOMMODATION_ROOM_TYPE = 'Own Arrangement';
export const OWN_ACCOMMODATION_SUPPLIER_CODE = 'OWN';
export const getLeadDays = () => Number(getFromEnv('DUE_DATE_LEAD_DAYS', DEFAULT_DUE_DATE_LEAD_DAYS));

export const paxToDefaultRooms = (adults, childrenAges) => {
  let adultsLeft = adults;
  let childrenAgesLeft = cloneDeep(childrenAges);
  let rooms = [];
  while (childrenAgesLeft.length > 0) {
    let children = childrenAgesLeft.length > 1 ? [childrenAgesLeft.shift(), childrenAgesLeft.shift()] : [childrenAgesLeft.shift()];
    let room = { roomType: 'fr', adults: 1, children };
    adultsLeft--;
    rooms.push(room);
  }
  while (adultsLeft > 0) {
    let room = { roomType: adultsLeft > 1 ? 'dr' : 'sr', adults: adultsLeft > 1 ? 2 : 1, children: [] };
    rooms.push(room);
    adultsLeft -= 2;
  }
  return rooms;
};

export function supplierIsOwnAccommodation(supplierCode) {
  return supplierCode === OWN_ACCOMMODATION_SUPPLIER_CODE;
}

export const getItineraryDueDate = instance => {
  const today = moment()
    .utc()
    .startOf('day');
  let dueDate = moment(get(instance, 'itinerary.startDate'))
    .utc()
    .startOf('day')
    .subtract(getLeadDays(), 'days');
  if (dueDate.isBefore(today)) {
    dueDate = today;
  }
  return dueDate;
};

export const omitHiddenSegments = segments => {
  const hiddenMap = reduce(
    segments,
    (acc, segment) => {
      if (segment?.sequence) {
        acc[segment.sequence] = segment?.hideFromClient && segment?.hideFromClient === true ? true : false;
      }
      return acc;
    },
    {},
  );

  return segments.reduce((segments, segment) => {
    if (
      (segment.hideFromClient && segment.hideFromClient === true) ||
      (segment.parentBySequence && get(hiddenMap, segment.parentBySequence, false) === true)
    ) {
      // Omit
      return segments;
    } else {
      segments.push(segment);
      return segments;
    }
  }, []);
};
export const associateServiceSegmentsToParent = segments => {
  let lastActivityParent;
  return segments.reduce((segments, segment) => {
    if (isValidActivityParent(segment)) {
      lastActivityParent = segment;
      segment.extras = [];
    }
    if (segment.type === SEGMENT_TYPE.SERVICE && lastActivityParent && lastActivityParent.extras) {
      lastActivityParent.extras.push(segment);
      return segments;
    }
    segments.push(segment);
    return segments;
  }, []);
};

export const flattenServiceSegmentsFromParent = segments => {
  return cloneDeep(segments).reduce((segments, segment) => {
    if (size(segment.extras) > 0) {
      const toAdd = segment.extras.map(extra => {
        // copy the extras, sequence is assumed to be correct on these
        return { ...extra };
      });
      delete segment.extras;
      // add the segment
      segments.push(segment);
      // add the extras
      segments = segments.concat(toAdd);
    } else {
      delete segment.extras;
      segments.push(segment);
    }
    return segments;
  }, []);
};

export const shouldHideSegment = segment => get(segment, 'hideFromClient', false) === true;

export const isValidActivityParent = (segment = {}) => segment.type === SEGMENT_TYPE.STAY || segment.type === SEGMENT_TYPE.POINT;

export const shouldOnlyHaveBalance = (itinerary, depositOverride) => {
  let ret = false;

  // Old itineraries don't have a createdDate
  if (get(itinerary, 'createdDate')) {
    const created = moment(get(itinerary, 'createdDate')).startOf('day');
    const balanceDueDate = getPaymentDueDate(itinerary, PAYMENT_TYPE.BALANCE).startOf('day');
    ret = balanceDueDate.isSameOrBefore(created);
  }
  // If we have a depositOverride that is zero then we only do balance
  // If not then we only do balance if we previously set deposit to zero
  // checking sell is non-zero to avoid doing only balance the first reprice
  if (!isUndefined(depositOverride)) {
    ret = ret || depositOverride.isZero();
  }

  return ret;
};

export const segmentsDateFloat = (startDateItinerary, startDateSaved, segments, preserveSellPrice = false) => {
  const momentItinerary = moment(startDateItinerary, 'YYYY-MM-DD');
  const momentSaved = moment(startDateSaved, 'YYYY-MM-DD');
  const momentDiff = momentItinerary.diff(momentSaved, 'days');
  map(segments, segment => {
    if (segment.startDate) {
      segment.startDate = moment(segment.startDate, 'YYYY-MM-DD')
        .add(momentDiff, 'days')
        .format('YYYY-MM-DD');
    }
    if (segment.endDate) {
      segment.endDate = moment(segment.endDate, 'YYYY-MM-DD')
        .add(momentDiff, 'days')
        .format('YYYY-MM-DD');
    }

    delete segment.linked; // Remove DMC association.

    // Remove segment costs, will reprice.
    delete segment.cost;
    delete segment.currency;
    if (!preserveSellPrice) delete segment.sell;
  });
  log.debug(startDateItinerary, startDateSaved, momentDiff, segments);
  return segments;
};

export const costChangePaths = [
  // cost changes
  'costs.supplierTotal',
  'deposits.supplierTotal',
  // margin changes
  'margins.total',
  // pricing changes
  'finance.sell',
  'finance.sellingPrice',
  'deposits.total',
  'finance.currency',
];

export function travelersText(adults = 0, children = 0) {
  if (!adults && !children) return '';
  return [
    adults > 0 && `${adults} ${adults === 1 ? 'Adult' : 'Adults'}`,
    children > 0 && `${children} ${children === 1 ? 'Child' : 'Children'}`,
  ]
    .filter(Boolean)
    .join(', ');
}

export function totalTravelersText(adults = 0, children = 0) {
  const a = Number.parseInt(adults, 10);
  const c = Number.parseInt(children, 10);
  const totalTravelers = a + c;
  if (totalTravelers < 0) {
    return '';
  }

  return [totalTravelers >= 0 && totalTravelers === 1 ? '1 Traveler' : `${totalTravelers} Travelers`, travelersText(a, c)]
    .filter(Boolean)
    .join(': ');
}
