import { IconButton, ListItemButton, ListItemText, Popover } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useApi } from 'common/hooks/api';
import { useSnackbar } from 'notistack';

const escapeCsvValue = value => (value ? `"${String(value).replace(/"/g, '""')}"` : '');

export const convertJSONToCSV = (headers, data) => {
  // have to pass in headers because not all the rows have all the fields
  if (!data.length) {
    return '';
  }
  try {
    const output = [];
    output.push(headers.map(header => escapeCsvValue(header.display)).join(','));
    data.forEach(row =>
      output.push(
        headers
          .map(header => {
            const { key, render } = header;
            const data = render ? render(row[key]) : row[key];
            return escapeCsvValue(data);
          })
          .join(','),
      ),
    );
    return output.join('\n');
  } catch {
    return '';
  }
};
/**
 *
 * @param exportType { string } "Accommodations, Services, Regions or Countries"
 * @param exportMethod { string } "Dynamic reference method to API
 * @param headers { key, display }
 * @param filters { object }
 * @param count { number }
 * @param fileName { string }
 * @param Icon { string | JSX.Element }
 * @returns {JSX.Element}
 * @constructor
 */
export const JsonToCsv = ({ exportType, exportMethod, headers, filters, count, fileName, Icon }) => {
  //TODO - debug why no updated by
  const Api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(undefined);

  const onOpenMenu = useCallback(
    e => {
      setAnchorEl(e.currentTarget);
    },
    [setAnchorEl],
  );

  const onCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const open = Boolean(anchorEl);
  const id = open ? 'download-csv' : undefined;

  const requestAndDownload = useCallback(
    async f => {
      try {
        setLoading(true);
        const response = await Api[exportMethod](f);
        setLoading(false);
        const blob = new Blob([convertJSONToCSV(headers, response.data.data)], { type: 'text/csv;charset=utf-8;' });
        const d = document.createElement('a');
        d.setAttribute('href', URL.createObjectURL(blob));
        d.setAttribute('download', fileName || 'export.csv');
        document.body.appendChild(d);
        d.click();
        document.body.removeChild(d);
      } catch {
        setLoading(false);
        enqueueSnackbar(`An error occurred while downloading ${exportType} CSV.`, {
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
          className: 'network-snack',
        });
      }
    },
    [setLoading, Api, fileName, headers, exportMethod, exportType, enqueueSnackbar],
  );

  const onDownloadAll = useCallback(async () => {
    const f = {
      ...filters,
      limit: count,
      skip: 0,
      fields: headers.map(h => h.key),
    };
    await requestAndDownload(f);
  }, [filters, requestAndDownload, count, headers]);

  const onDownloadPage = useCallback(async () => {
    const f = {
      ...filters,
      fields: headers.map(h => h.key),
    };
    await requestAndDownload(f);
  }, [filters, requestAndDownload, headers]);

  return (
    <>
      <IconButton color="primary" className="hoverable" aria-label="download csv" onClick={onOpenMenu}>
        {!loading && typeof Icon === 'string' ? Icon : <Icon />}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ListItemButton onClick={onDownloadAll} className="hoverable">
          <ListItemText primary="Download All" />
        </ListItemButton>
        <ListItemButton onClick={onDownloadPage} className="hoverable">
          <ListItemText primary="Download Current" />
        </ListItemButton>
      </Popover>
    </>
  );
};
