import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Typography, Grid } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import makeStyles from '@mui/styles/makeStyles';

import Button from '../Button';
import UploadIcon from '../Icons/Upload';
import XIcon from '../Icons/X';
import { BasicModal, ModalActions, ModalTitle, ModalContent } from './BasicModal';
import sharedStyles from 'styles/shared';
import { useUploadMediaMutation, useUploadToS3Mutation, useUpdateMediaContentMutation } from 'slices/index';
import LoadingOverlay from '../LoadingOverlay';

const useStyles = makeStyles(theme => ({
  errorMessage: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.error.main,
  },
  previewImg: {
    borderColor: theme.palette.stoneGrey.black40,
    borderWidth: '1px',
    borderStyle: 'solid',
  },
  remove: {
    cursor: 'pointer',
    width: theme.spacing(2),
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 100,
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1.2),
    borderWidth: '2px',
    borderRadius: 0,
    borderColor: theme.palette.stoneGrey.black40,
    borderStyle: 'dashed',
    color: theme.palette.stoneGrey.black80,
    outline: 'none',
    transition: 'border 0.24s ease-in-out',
    cursor: 'pointer',
  },
}));

export default function PhotoUploaderModal({ open, handleClose, updateMutation, onUploadSuccess, mediaMetadata = {}, orderOffset = 0 }) {
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
  });
  const classes = useStyles();
  const s = sharedStyles();
  const [error, setError] = useState('');
  const [preview, setPreview] = useState([]);
  const [uploadMedia, { isLoading: isUploading, error: uploadError }] = useUploadMediaMutation();
  const [uploadToS3, { isLoading: isUploadingToS3, error: uploadToS3Error }] = useUploadToS3Mutation();
  const [updateMediaContent, { isLoading: isUpdatingMediaContent }] = useUpdateMediaContentMutation();

  const loading = isUploading || isUploadingToS3 || isUpdatingMediaContent;

  useEffect(() => {
    const err = uploadError || uploadToS3Error;
    if (err) setError('There was an error, please refresh the page.');
  }, [uploadError, uploadToS3Error]);

  const handleCancel = () => {
    setPreview([]);
    handleClose();
  };

  const handleUpload = async () => {
    try {
      const files = preview.map(p => p.file);
      const res = await uploadMedia({ files, mediaMetadata, orderOffset });
      await new Promise(resolve => setTimeout(() => resolve({}), 500));
      await uploadToS3(
        res.data.map((d, idx) => ({
          data: d,
          file: acceptedFiles[idx],
        })),
      );
      await updateMediaContent(res.data.map(updateMutation));
      onUploadSuccess(res);
    } catch {
      setError('Error uploading files!');
    }
    setPreview([]);
    handleClose();
  };

  const removeFile = file => {
    const newFiles = [...preview];
    newFiles.splice(newFiles.indexOf(file), 1);
    setPreview(newFiles);
  };

  useEffect(() => {
    const newFile = acceptedFiles[0];
    if (!newFile) return;
    const previewFiles = acceptedFiles.map(file => ({
      url: URL.createObjectURL(file),
      path: file.path,
      file: file,
    }));
    setPreview(p => [...previewFiles, ...p]);
    setError('');
  }, [acceptedFiles]);

  useEffect(() => {
    if (Array.isArray(fileRejections) && fileRejections.length > 0) {
      setError('Only image and video files are allowed.');
    }
  }, [fileRejections]);

  // clean up on unmount
  useEffect(() => {
    return () => {
      setPreview([]);
    };
  }, []);

  return (
    <BasicModal open={open} onClose={handleCancel} fullWidth maxWidth="sm" name="upload photos">
      <LoadingOverlay open={loading} />
      <ModalTitle onClose={handleCancel}>Upload Photos</ModalTitle>
      <ModalContent>
        <Typography variant="body2">
          Please note that a photo resolution of 1920 x 1080 is recommended for optimal viewing. To upload photos drag and drop them into
          the upload field or click 'Upload from Computer'.
        </Typography>

        {error && (
          <Typography className={classes.errorMessage} variant="body2">
            {error}
          </Typography>
        )}

        {preview.length > 0 && (
          <Grid container spacing={1} className={s.mtMd}>
            {preview.map(file => (
              <Grid key={file.path} item xs={12} sm={3} className={s.relative}>
                <img className={clsx(s.fullWidth, classes.previewImg)} src={file.url} alt="preview" />
                <div className={classes.remove} onClick={() => removeFile(file)}>
                  <XIcon color="red" />
                </div>
              </Grid>
            ))}
          </Grid>
        )}

        <Grid className={s.mtLg} container spacing={2}>
          <Grid item xs={12} sm={9}>
            <div {...getRootProps({ className: classes.dropzone })} role="button" data-test-id="dropzone">
              <div className={s.flexRow}>
                <UploadIcon />
                <Typography className={s.mlXs} variant="body2">
                  Drag and Drop photos or UPLOAD FROM COMPUTER
                </Typography>
              </div>
              <input {...getInputProps()} />
            </div>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Button variant="contained" onClick={handleUpload} disabled={!preview || preview.length === 0} className={s.backgroundSuccess}>
              ADD PHOTOS
            </Button>
          </Grid>
        </Grid>
      </ModalContent>

      {/* necessary for spacing */}
      <ModalActions />
    </BasicModal>
  );
}
