import { useCallback, useState } from 'react';
import Imgix from 'react-imgix';
import { IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackwardsIosIcon from '@mui/icons-material/ArrowBackIos';
import { BasicModal } from 'components/v2/Modals/BasicModal';
import { cdnUri } from 'common/helpers/content';
import sharedStyles from '../../../../styles/shared';

export const PhotoModal = ({ open, onClose, mediaIndex, mediaContent }) => {
  const s = sharedStyles();
  const [index, setIndex] = useState(mediaIndex);
  const mediaItem = mediaContent?.[index];

  const onArrowClick = useCallback(
    index => {
      if (index < 0) {
        index = mediaContent.length - 1;
      } else if (index === mediaContent.length) {
        index = 0;
      }
      setIndex(index);
    },
    [mediaContent, setIndex],
  );
  if (!open) {
    return <></>;
  }

  // maybe prefetch images to improve speed on
  return (
    <BasicModal open={open} onClose={onClose} fullWidth maxWidth="lg">
      <div>
        <IconButton
          color="secondary"
          onClick={() => onArrowClick(index - 1)}
          style={{ position: 'absolute', left: 5, top: '50%' }}
          variant="contained"
          aria-label="previous image"
        >
          <ArrowBackwardsIosIcon />
        </IconButton>
        <IconButton
          color="secondary"
          onClick={() => onArrowClick(index + 1)}
          style={{ position: 'absolute', right: 5, top: '50%' }}
          variant="contained"
          aria-label="next image"
        >
          <ArrowForwardIosIcon />
        </IconButton>
        <Imgix className={s.fullWidth} src={cdnUri(mediaItem)} width={900} />
      </div>
    </BasicModal>
  );
};
