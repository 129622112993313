import { get, forEach } from 'lodash';

import FeatureFlags from '../FeatureFlags';

const hasCapability = (user, capability) => get(user, 'capabilities', []).includes(capability);

const isInAgencies = (user, agencies) => {
  let ret = false;
  forEach(agencies, agency => {
    ret = ret || get(user, 'agency.agencyCode') === agency;
  });
  return ret;
};

export const isSuperAdmin = user => hasCapability(user, 'superadmin');
export const isSuperContentManager = user => isSuperAdmin(user);
export const isContentManager = user =>
  isSuperAdmin(user) ||
  hasCapability(user, 'contentmanager') ||
  isInAgencies(user, get(FeatureFlags, 'allContentManagerAgencies.agencies'));
export const isAdmin = user => isSuperAdmin(user) || get(user, 'agency.admins', []).includes(get(user, 'userId', ''));
export const isAuthenticated = (user, allowsGuest) => {
  const hasUser = !!user;
  const isGuest = get(user, 'principalType') === 'Guest';
  return (hasUser && allowsGuest) || (hasUser && !isGuest);
};
