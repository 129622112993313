import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

import { useCampDetails } from '../../hooks';
import PhotoGrid from '../../../components/PhotoGrid';
import FileUploaderModal from '../../../../../components/v2/Modals/FileUploaderModal';
import { useUser } from 'common/hooks/user';

//TODO - what happens is itrvl006 make edits uploads images in legacy media content library
export const Content = () => {
  const params = useParams();
  const supplierCode = params.supplierCode;
  const { user } = useUser();
  const { campInfo, mediaContent, save } = useCampDetails(supplierCode);

  const [selected, setSelected] = useState([]);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);

  const onChange = async photos => {
    const mediaContentPatch = { ...campInfo.mediaContentPatch, photos };
    await save(campInfo.supplierCode, { campId: campInfo.supplierCode, mediaContentPatch });
  };

  const onDeleteOne = async photo => {
    const photos = (campInfo?.mediaContent?.photos ?? mediaContent).filter(p => p.id !== photo.id);
    await onChange(photos);
  };

  const onDeleteMany = async () => {
    const photos = (campInfo?.mediaContent?.photos ?? mediaContent).filter(p => !selected.includes(p.id));
    await onChange(photos);
    setSelected([]);
  };

  const toggleSelected = e => {
    const id = e.target.value;
    if (selected.includes(id)) {
      setSelected(selected => selected.filter(x => x !== id));
    } else {
      setSelected(selected => [...selected, id]);
    }
  };

  const onUploadSuccess = async res => {
    res.data.map(img => ({
      ...img,
      id: img.id,
      presentationVisibility: true,
    }));
    await onChange([...res.data, ...(campInfo?.mediaContent?.photos ?? mediaContent)]);
  };
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body1" mb={4}>
          Accommodation Content
        </Typography>
        <div>
          <Button variant="outlined" sx={{ mr: 1 }} onClick={onDeleteMany} disabled={selected.length === 0}>
            Delete Selection
          </Button>
          <Button variant="outlined" onClick={() => setUploadModalVisible(true)}>
            Add Photos
          </Button>
        </div>
      </div>
      {/*TODO - verify region carousel do no come though in production*/}
      <PhotoGrid
        mediaContent={campInfo?.mediaContent?.photos ?? mediaContent}
        handleToggle={toggleSelected}
        handleDelete={onDeleteOne}
        selected={selected}
        handleChange={onChange}
      />
      <FileUploaderModal
        open={uploadModalVisible}
        handleClose={setUploadModalVisible}
        mediaMetadata={{
          agencyContent: true,
        }}
        orderOffset={mediaContent?.length}
        onUploadSuccess={onUploadSuccess}
        updateMutation={d => {
          return {
            id: d.id,
            collections: ['Agency Upload', 'Accommodation'],
            country: campInfo.countryCode,
            region: campInfo.countryRegionCode,
            processed: true,
            status: 'approved',
            accommodation: campInfo.supplierCode,
            agencyId: user?.agency?.id,
          };
        }}
      />
    </>
  );
};
