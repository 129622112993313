/**
 * @enum {string}
 * @description Represents the different types of itinerary events.
 */
export const ITINERARY_EVENT = {
  QUOTE_CREATED: 'quote_created',
  QUOTE_IMPORTED: 'quote_imported',
  COPIED_TO_BUILDER: 'copied_to_builder',
  QUOTE_DUPLICATED: 'quote_duplicated',
  RESTORED_VERSION: 'restored_version',
  SEGMENTS_MODIFIED: 'segments_modified',
  INVOICE_GENERATED: 'invoice_generated',
  INVOICE_PAID: 'invoice_paid',
  INVOICE_MARKED_AS_PAID: 'invoice_marked_as_paid',
  INVOICE_UPDATED: 'invoice_updated',
  STATUS_CHANGED: 'status_changed',
  ACTIVITIES_EDITED: 'activities_edited',
  TRANSIT_EDITED: 'transit_edited',
  ACCOMMODATION_MODIFIED: 'accommodation_modified',
  DMC_COST_ADDED: 'dmc_cost_added',
};
