import { Dinero, getRspInTripCurrency, rateLimits, getItineraryCurrencies } from 'itrvl-pricing';
import Model from './Model';
import { formatDateRange, parseDate } from 'utils/dates';
import { find, reduce, clone, size, keys, cloneDeep, forEach, uniq, values, isArray, get, merge, startCase } from 'lodash';
import hareNiemeyer from 'utils/hare-niemeyer';
import {
  SEGMENT_TYPE,
  toCamelCase,
  getItineraryFlag,
  ITINERARY_FLAG,
  ITINERARY_STATE,
  getCurrencySymbol,
  PAYMENT_STATUS,
  ITRVL_BOOKING_STATUS,
  INVENTORY_STATUS,
  canPay,
  OWN_ACCOMMODATION_ROOM_TYPE,
  getLeadDays,
  hasItineraryFlag,
  totalTravelersText,
  travelersText as itrvlTravelersText,
  itineraryStateEditTransit,
  itineraryStateEditActivities,
} from 'itrvl-types';
import { cdnUri } from 'common/helpers/content';
import { isWithinInterval, addDays } from 'date-fns';

const isPackageDetail = segment => segment.sequence >= 100;
const PAYMENT_MODIFY_STATES = [ITINERARY_STATE.CONFIRMED, ITINERARY_STATE.CONFIRM_REQUESTED, ITINERARY_STATE.PROVISIONAL];
const travelersSort = (a, b) => {
  if (a.lead && !b.lead) {
    return -1;
  }
  if (!a.lead && b.lead) {
    return 1;
  }

  const aHasClientId = 'clientId' in a && a.clientId !== null;
  const bHasClientId = 'clientId' in b && b.clientId !== null;

  if (aHasClientId && !bHasClientId) {
    return -1;
  }
  if (!aHasClientId && bHasClientId) {
    return 1;
  }

  return a.name.localeCompare(b.name);
};

class Itinerary extends Model {
  constructor(properties) {
    super(properties, {
      parties: [],
      travelers: [],
      payments: [],
    });
    if (isArray(this.parties)) {
      this.parties = this.parties.map(party => {
        party.travelers = party.travelers.sort(travelersSort);
        return party;
      });
    }
  }

  get canEditTransit() {
    return itineraryStateEditTransit(this.itinerary.state);
  }

  get canEditActivities() {
    return itineraryStateEditActivities(this.itinerary.state);
  }

  get accommodationsFromSegments() {
    // Shims `itinerary.itinerary.params.accommodations` until it is set (post Window).
    return reduce(
      this.itinerary.segments,
      (acc, segment) => {
        if (segment.type === 'stay') acc.push(segment);
        return acc;
      },
      [],
    );
  }

  get accommodationList() {
    if (this.itinerary?.quoteId) {
      return uniq(
        this.itinerary?.segments?.filter(segment => segment.type === SEGMENT_TYPE.STAY).map(acc => acc.label || acc.description),
      ).join(', ');
    } else {
      return 'Fetching';
    }
  }

  get adults() {
    return this.itinerary?.adults ?? 0;
  }

  get adjustedSell() {
    return this.sell + this.sellServiceFee;
  }

  get agentDeposit() {
    return this.deposits?.agentTotal ?? 0;
  }

  get allAdults() {
    return reduce(
      this.parties,
      (acc, party) => {
        const { travelers = [] } = party;
        forEach(travelers, traveler => {
          acc.push(traveler);
        });
        return acc;
      },
      [],
    );
  }

  get availableAdults() {
    return reduce(
      this.parties,
      (acc, party) => {
        const { travelers = [] } = party;
        forEach(travelers, traveler => {
          if (!traveler.lead && traveler.adult) {
            acc.push(traveler);
          }
        });
        return acc;
      },
      [],
    );
  }

  get balance() {
    return this.finance?.balance ?? 0;
  }

  get balanceServiceFee() {
    return this.finance?.balance_fee_credit ?? 0;
  }

  get canChangeCurrency() {
    return !this.hasPaid && !this.pricingLocked && !this.itinerary.state !== 'cancelled';
  }

  get canGenerateInvoices() {
    return PAYMENT_MODIFY_STATES.includes(this.itinerary.state) && !this.hasPayments;
  }

  get canModifyPayments() {
    return PAYMENT_MODIFY_STATES.includes(this.itinerary.state) && this.hasPayments;
  }

  get canPay() {
    return canPay(this);
  }

  get children() {
    return this.itinerary?.children ?? 0;
  }

  get clientName() {
    return this.client?.name || '';
  }

  clone() {
    return new Itinerary(cloneDeep(this._dataValues));
  }

  get currencies() {
    return getItineraryCurrencies(this) || [];
  }

  get currency() {
    return this.finance?.currency || 'USD';
  }

  get currencySymbol() {
    return getCurrencySymbol(this.currency);
  }

  get customPriceAdjustment() {
    return (this.finance.customPriceAdjustment ?? 0) - (this.finance.customPriceAdjustmentFee ?? 0);
  }

  get dateRangeText() {
    if (!this.itinerary?.startDate || !this.itinerary?.endDate) {
      return '';
    }
    return formatDateRange(this.startDateObject, this.endDateObject, { appendSameYear: true });
  }

  get deposit() {
    return this.finance?.deposit ?? 0;
  }

  get depositRate() {
    return this.rates?.deposit ?? rateLimits.deposit.override;
  }

  get depositServiceFee() {
    return this.finance?.deposit_fee_credit ?? 0;
  }

  get discount() {
    return this.finance?.discount ?? 0;
  }

  get endDateObject() {
    return parseDate(this.itinerary?.endDate);
  }

  get extraAdults() {
    return this.partyAdults - this.adults;
  }

  get extraChildren() {
    return this.partyChildren - this.children;
  }

  featuredImage(options = {}) {
    const s3Key = get(this, 'itinerary.params.customization.featuredImage.s3Key') ?? get(this, 'videoPoster.s3Key') ?? 'media-default.jpg';
    return cdnUri({ s3Key }, merge({ w: 320, ar: '16:9' }, options));
  }

  get globalStatus() {
    // @todo: var pending?
    return this.pricingError ? 'pending' : this.itinerary.state;
  }

  get globalStatusLabel() {
    let label = startCase(this.globalStatus);
    if (this.globalStatus === ITRVL_BOOKING_STATUS.QUOTE) {
      label = 'Quoted';
    } else if (this.globalStatus === ITRVL_BOOKING_STATUS.UNKNOWN) {
      label = 'Pending';
    } else if (this.globalStatus === ITRVL_BOOKING_STATUS.CANCELLED) {
      label = 'Cancelled';
    } else {
      if (this.segmentsInventoryStatusMatchesBookingStatus) {
        if (this.globalStatus === ITRVL_BOOKING_STATUS.PROVISIONAL) {
          label = 'Provisional Hold';
        }
      } else {
        if (this.globalStatus === ITRVL_BOOKING_STATUS.CONFIRMED) {
          label = 'Confirmation Requested';
        } else if (this.globalStatus === ITRVL_BOOKING_STATUS.PROVISIONAL) {
          label = 'Hold Requested';
        }
      }
    }
    return label;
  }

  get hasCustomMargin() {
    return (this.margins.custom ?? 0) > 0;
  }

  get hasCustomPriceAdjustment() {
    return this.finance?.customPriceAdjustment > 0;
  }

  get hasDmc() {
    // @todo: this _could_ be a good candidate to flag an itinerary onSave on the API so we're not having to track it down
    return this.segments.some(segment => segment.type === SEGMENT_TYPE.PRICE);
  }

  get hasExtraAdults() {
    return this.totalAdults > this.itinerary.adults;
  }

  get hasExtraChildren() {
    return this.totalChildren > this.itinerary.children;
  }

  get hasMarginProblem() {
    return Boolean(this.problems?.margins?.total);
  }

  get hasMultipleCurrencies() {
    return size(keys(this.costs.supplier)) > 0 && !(this.currency in this.costs.supplier);
  }

  get hasMultipleParties() {
    return size(this.parties) > 1;
  }

  get hasNonLeadAdults() {
    return this.parties.some(party => party.travelers.some(traveler => traveler.adult === true && !traveler.lead));
  }

  get hasOwnAccommodation() {
    return this.segments.some(segment => segment.roomType === OWN_ACCOMMODATION_ROOM_TYPE);
  }

  get hasPaid() {
    return this.paid + this.serviceFee + this.discount > 0;
  }

  get hasPaidPayments() {
    return this.payments.some(payment => [PAYMENT_STATUS.PAID, PAYMENT_STATUS.AUTH].includes(payment.status));
  }

  get hasPayments() {
    return size(this.payments) > 0;
  }

  get hasSuppressMarginFlag() {
    return hasItineraryFlag(this, ITINERARY_FLAG.SUPPRESS_PROBLEM_NEGATIVE_MARGIN);
  }

  get invoiceDelta() {
    return this.invoiceTotal - this.totalSell;
  }

  get invoicePercentage() {
    if (this.totalSell === 0) {
      return 0;
    }

    return Math.round((this.invoiceTotal / this.totalSell) * 100);
  }

  get invoiceTotal() {
    return this.payments.reduce((total, payment) => {
      total += payment.amount + payment.serviceFee + -1 * (payment.discount ?? 0);
      return total;
    }, 0);
  }

  get isConfirmed() {
    return [ITINERARY_STATE.CONFIRMED, ITINERARY_STATE.CONFIRM_REQUESTED, ITINERARY_STATE.PROVISIONAL].includes(this.itinerary.state);
  }

  get isCancelled() {
    return this.itinerary.state === ITINERARY_STATE.CANCELLED;
  }

  get isHeld() {
    return this.itinerary.state === ITINERARY_STATE.PROVISIONAL;
  }

  get canChangeStartDate() {
    return this.canCopyToBuilder; // Same logic for now.
  }

  get whyNotChangeStartDate() {
    return this.whyNotCopyToBuilder; // Same logic for now.
  }

  get whyNotCopyToBuilder() {
    // false: Split party.
    // true: Past should be okay.
    // ...: ....
    //this.isConfirmed || // BackToBuilder.
    //this.isCancelled || // BackToBuilder.
    //get(this, 'itinerary.params.accommodations', []).filter(accommodation => accommodation.pending === true).length || // BackToBuilder.

    switch (true) {
      case !!this.hasDayStay:
        return 'Disabled due to itinerary containing a Day Use Room. Contact support for help.';
      case !!this.isLockedIncompatible:
        return 'Disabled due to itinerary locked/incompatible. Contact support for help.';
      default:
        return false;
    }
  }

  get canCopyToBuilder() {
    return !this.whyNotCopyToBuilder;
  }

  get hasDayStay() {
    // hasDayUseRoom
    return Boolean(find(this.itinerary.segments, { type: SEGMENT_TYPE.STAY, nights: 0 }));
  }

  get isLockedIncompatible() {
    return getItineraryFlag(this, ITINERARY_FLAG.LOCKED_INCOMPATIBLE);
  }

  get isPublished() {
    return this.published === true;
  }

  get isWithinLeadDays() {
    if (this.startDate) {
      const today = new Date();
      return isWithinInterval(this.startDateObject, { start: today, end: addDays(today, getLeadDays()) });
    }
    return false;
  }

  get marginPercentage() {
    const divisor = this.totalSell - this.sellServiceFee;
    if (divisor === 0) {
      return 0;
    } else {
      return (this.marginTotal / divisor) * 100;
    }
  }

  get marginTotal() {
    return this.margins?.total ?? 0;
  }

  get nights() {
    return this.itinerary?.nights ?? 0;
  }

  get nightsText() {
    return `${this.nights} ${this.nights === 1 ? 'Night' : 'Nights'}`;
  }

  get packageDetails() {
    return this.segments.filter(segment => isPackageDetail(segment));
  }

  get paid() {
    return this.finance?.paid ?? 0;
  }

  get partyAdults() {
    return this.parties.reduce((count, party) => {
      let adultsInParty = party.travelers.filter(traveler => traveler.adult).length;
      return count + adultsInParty;
    }, 0);
  }

  // @todo: add an overallocated one
  get partyAllocations() {
    const { parties = [], pax } = this;
    if (size(parties) === 0) {
      return [];
    }
    const input = parties.reduce((acc, party) => {
      const { travelers = [], id } = party;
      acc[id] = size(travelers);
      return acc;
    }, {});
    const totalTravelers = values(input).reduce((acc, val) => (acc += val), 0);
    const target = (totalTravelers / pax) * 100;
    // map back to expected value
    const apportionment = hareNiemeyer(input, target).reduce((acc, apportionment) => {
      acc[apportionment.party] = apportionment.seats;
      return acc;
    }, {});
    return apportionment;
  }

  get partyChildren() {
    return this.parties.reduce((count, party) => {
      let childrenInParty = party.travelers.filter(traveler => traveler.adult !== true).length;
      return count + childrenInParty;
    }, 0);
  }

  get pax() {
    return this.itinerary?.pax ?? 0;
  }

  get pricingLocked() {
    return getItineraryFlag(this, ITINERARY_FLAG.GENERATE_PAYMENTS);
  }

  get rackMarginTotal() {
    return this.margins?.rack ?? 0;
  }

  get rsp() {
    return reduce(
      this.segments,
      (total, segment) => {
        total = total.add(getRspInTripCurrency(segment, this));
        return total;
      },
      Dinero({ amount: 0, currency: this.currency }),
    );
  }

  get sell() {
    return this.finance?.sell ?? 0;
  }

  get sellServiceFee() {
    return this.finance?.sell_fee_credit ?? 0;
  }

  get segments() {
    return this.itinerary?.segments || [];
  }

  segmentHasPricingAllocations(segmentId) {
    return this.parties.some(party => party.allocations?.[segmentId]);
  }

  get segmentsInterleaveIssues() {
    const segments = this.segmentsNestedNoDmc; // Add in DMC before save.
    const issues = toCamelCase(this.itinerary.issuesWithKeys ?? []);
    const si = []; // Handle issues proper.
    for (let i = 0, len = segments.length, issuesI = 0; i < len; i++) {
      const issue = issues[issuesI];
      const segment = segments[i];
      si.push(segment);
      if (segment.type === 'accommodation') {
      }
      if (segment.fkKey && segment.fkKey === issue?.fkKeyFrom) {
        // type==[entry,exit] has no fkKey.
        for (let j = i; j < len; j++) {
          if (segments[j].type === 'accommodation') {
            break;
          }
        }
        si.push({ ...issue, type: 'issue' });
        issuesI++;
      }
    }
    const sii = []; // Handle malformed Window data.
    for (let i = 0, len = si.length; i < len; i++) {
      const segment = si[i];
      sii.push(segment);
      if ([SEGMENT_TYPE.STAY, SEGMENT_TYPE.POINT].includes(segment.type)) {
        if ([SEGMENT_TYPE.STAY, SEGMENT_TYPE.POINT].includes(si[i + 1]?.type)) {
          sii.push({ issue: 'Travel missing gap.', type: 'issue' });
        }
      }
    }
    return sii;
  }

  get segmentsInventoryStatusMatchesBookingStatus() {
    const bookingStatus = this.itinerary.state;
    if (size(this.segments) === 0) {
      return false;
    }
    const filtered = this.segments.filter(segment => segment.type === SEGMENT_TYPE.STAY && segment.inventoryStatus);
    return (
      size(filtered) > 0 &&
      filtered.every(segment => {
        let { inventoryStatus } = segment;
        // we need to convert these cases so we can match itrvl bookingStatus and ww inventoryStatus
        if (inventoryStatus === INVENTORY_STATUS.DRAFT) {
          inventoryStatus = ITRVL_BOOKING_STATUS.QUOTE;
        } else if (inventoryStatus === INVENTORY_STATUS.CONFIRMED) {
          inventoryStatus = ITRVL_BOOKING_STATUS.CONFIRMED;
        }
        return inventoryStatus.toLowerCase() === bookingStatus;
      })
    );
  }

  get segmentsOnlyDmc() {
    const collection = this.segments.filter(segment => isPackageDetail(segment));
    return collection;
  }

  get segmentsNestedNoDmc() {
    const collection = this.segments;
    const result = [];
    let currentStayObject = null;

    for (let i = 0; i < collection.length; i++) {
      const currentObject = collection[i];

      // TODO remove this if
      if (currentObject.type === SEGMENT_TYPE.ENTRY || currentObject.type === SEGMENT_TYPE.EXIT) {
        //continue;
      }

      // filter out package details
      if (isPackageDetail(currentObject)) {
        continue;
      }

      // relate accommodation/point services to accommodation/point
      if ([SEGMENT_TYPE.STAY, SEGMENT_TYPE.POINT].includes(currentObject.type)) {
        currentStayObject = clone(currentObject);
        result.push(currentStayObject);
      } else if (currentObject.type === SEGMENT_TYPE.SERVICE) {
        if (currentStayObject) {
          if (!currentStayObject.services) {
            currentStayObject.services = [];
          }
          currentStayObject.services.push(currentObject);
        } else {
          result.push(currentObject);
        }
      } else {
        currentStayObject = null;
        result.push(currentObject);
      }

      // If the next object's type is not "service", set currentStayObject to null
      if (i < collection.length - 1 && collection[i + 1].type !== SEGMENT_TYPE.SERVICE) {
        currentStayObject = null;
      }
    }

    return result;
  }

  // @todo: maybe move this as a memo in the UI layer
  get segmentsForUi() {
    return this._getCachedValue('segmentsForUi', () => {
      const collection = this.segments;
      const result = [];
      let currentStayObject = null;

      for (let i = 0; i < collection.length; i++) {
        const currentObject = collection[i];

        if (currentObject.type === SEGMENT_TYPE.ENTRY || currentObject.type === SEGMENT_TYPE.EXIT) {
          continue;
        }

        // filter out package details
        if (isPackageDetail(currentObject)) {
          continue;
        }

        // relate accommodations services to accommodation
        if (currentObject.type === SEGMENT_TYPE.STAY) {
          currentStayObject = clone(currentObject);
          result.push(currentStayObject);
        } else if (currentObject.type === SEGMENT_TYPE.SERVICE) {
          if (currentStayObject) {
            if (!currentStayObject.services) {
              currentStayObject.services = [];
            }
            currentStayObject.services.push(currentObject);
          } else {
            result.push(currentObject);
          }
        } else {
          currentStayObject = null;
          result.push(currentObject);
        }

        // If the next object's type is not "service", set currentStayObject to null
        if (i < collection.length - 1 && collection[i + 1].type !== SEGMENT_TYPE.SERVICE) {
          currentStayObject = null;
        }
      }
      return result;
    });
  }

  get segmentsForUiGroupedByDate() {
    return this._getCachedValue('segmentsForUiGroupedByDate', () => {
      return this.itinerary?.segments.reduce((acc, segment) => {
        if (segment.type === SEGMENT_TYPE.ENTRY || segment.type === SEGMENT_TYPE.EXIT) {
          return acc;
        }

        // filter out package details
        if (isPackageDetail(segment)) {
          return acc;
        }
        if (segment.startDate) {
          if (!acc[segment.startDate]) {
            acc[segment.startDate] = [];
          }
          acc[segment.startDate].push(segment);
        }
        return acc;
      }, {});
    });
  }

  get serviceFee() {
    return this.finance?.serviceFee ?? 0;
  }

  get shouldHideCurrencyConversion() {
    return size(this.costs?.supplier) === 1 && this.currency in this.costs?.supplier;
  }

  get state() {
    return this.itinerary?.state;
  }

  get startDate() {
    return this.itinerary?.startDate;
  }

  get startDateObject() {
    return parseDate(this.startDate);
  }

  get status() {
    return this.itinerary?.state;
  }

  get supplierCost() {
    return this.costs?.supplierTotal ?? 0;
  }

  get supplierDeposit() {
    return this.initialDeposit ?? this.deposits?.supplierTotal ?? 0;
  }

  get supplierTotal() {
    return this.costs?.supplierTotal;
  }

  get totalAmountDue() {
    return this.hasPayments ? this.invoiceTotal : this.totalSell;
  }

  get totalBalance() {
    return this.balance + this.balanceServiceFee ?? 0;
  }

  get totalDeposit() {
    return this.deposit + this.depositServiceFee;
  }

  get totalPaid() {
    return this.paid + this.serviceFee + this.discount;
  }

  get totalSell() {
    return this.sell + this.sellServiceFee;
  }

  get travelersText() {
    return itrvlTravelersText(this.adults, this.children);
  }

  get totalTravelersText() {
    return totalTravelersText(this.adults, this.children);
  }

  get childrenAgeText() {
    // This assumes that no new travelers will join the itinerary that weren't at the start
    if (!this.children) {
      return '';
    }
    let ages = this.itinerary.params.rooms.reduce((acc, room) => {
      if (room.children && room.children.length) {
        room.children.forEach(age => acc.push(age));
      }
      acc.sort((a, b) => b - a);

      return acc;
    }, []);
    return ages.join(', ');
  }

  get upfrontCost() {
    return this.costs?.upfrontTotal ?? 0;
  }
}

export default Itinerary;
